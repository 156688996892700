<mat-toolbar color="primary">
  <mat-toolbar-row>
    <a href="/dashboard" *ngIf="tokenStorage.getUser()" mat-button>
      {{ 'Navbar.home' | translate }}
    </a>
    <a href="/" *ngIf="!tokenStorage.getUser()" mat-button>
      {{ 'Navbar.home' | translate }}
    </a>
    <span class="free-space"></span>
    <button mat-icon-button (click)="sidenav.toggle()" fxShow="true" fxHide.gt-sm>
      <mat-icon>menu</mat-icon>
    </button>
    <div fxShow="true" fxHide.lt-md="true">
      <span *ngIf="user != null" class="name-span">
        {{ user.name }}
      </span>
      <a href="/dashboard" *ngIf="tokenStorage.getUser()" mat-button>
        {{ 'Navbar.dashboard' | translate }}
      </a>
      <button mat-button [matMenuTriggerFor]="menu1">{{selectedMenu | uppercase}}<mat-icon [style.font-size]="'22px'">arrow_drop_down</mat-icon></button>
      <mat-menu #menu1="matMenu">
        <button mat-menu-item *ngIf="selectedMenu != 'ro'" (click)="languageSelect('ro')">RO</button>
        <button mat-menu-item *ngIf="selectedMenu != 'en'" (click)="languageSelect('en')">EN</button>
        <button mat-menu-item *ngIf="selectedMenu != 'ru'" (click)="languageSelect('ru')">RU</button>
      </mat-menu>
      <a href="/login" *ngIf="!tokenStorage.getUser()" mat-button>
        {{ 'Navbar.login' | translate }}
        <!-- <mat-icon>directions_run</mat-icon> -->
      </a>
      <a href="/register" *ngIf="!tokenStorage.getUser()" mat-button>
        {{ 'Navbar.register' | translate }}
        <!-- <mat-icon>directions_run</mat-icon> -->
      </a>
      <a href="#" *ngIf="tokenStorage.getUser()" (click)="logout()" mat-button>
        {{ 'Navbar.logout' | translate }}
        <mat-icon>directions_run</mat-icon>
      </a>
    </div>
  </mat-toolbar-row>
</mat-toolbar>

<mat-sidenav-container>
  <mat-sidenav #sidenav position="end">
    <mat-nav-list>
      <a disabled *ngIf="user != null" mat-list-item>
        {{ user.name }}
      </a>
      <div mat-list-item>
        <a mat-list-item [matMenuTriggerFor]="menu2">{{selectedMenu | uppercase}}<mat-icon [style.font-size]="'22px'">arrow_drop_down</mat-icon></a>
        <mat-menu #menu2="matMenu">
          <button mat-menu-item *ngIf="selectedMenu != 'ro'" (click)="languageSelect('ro')">RO</button>
          <button mat-menu-item *ngIf="selectedMenu != 'en'" (click)="languageSelect('en')">EN</button>
          <button mat-menu-item *ngIf="selectedMenu != 'ru'" (click)="languageSelect('ru')">RU</button>
        </mat-menu>
      </div>
      <a href="/login" *ngIf="!tokenStorage.getUser()" mat-list-item>
        {{ 'Navbar.login' | translate }}
        <!-- <mat-icon>directions_run</mat-icon> -->
      </a>
      <a href="/register" *ngIf="!tokenStorage.getUser()" mat-list-item>
        {{ 'Navbar.register' | translate }}
        <!-- <mat-icon>directions_run</mat-icon> -->
      </a>
      <a href="/dashboard" *ngIf="tokenStorage.getUser()" mat-list-item>
        {{ 'Navbar.dashboard' | translate }}
      </a>
      <a href="#" *ngIf="tokenStorage.getUser()" (click)="logout()" mat-list-item>
        {{ 'Navbar.logout' | translate }}
        <mat-icon>directions_run</mat-icon>
      </a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <router-outlet></router-outlet>
    <!-- <app-footer></app-footer> -->
  </mat-sidenav-content>
</mat-sidenav-container>