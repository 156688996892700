import { CdkStepper } from '@angular/cdk/stepper';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormArray, Validators, FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OfferService } from 'src/app/services/offers.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';

@Component({
  selector: 'app-farmer',
  templateUrl: './farmer.component.html',
  styleUrls: ['./farmer.component.scss']
})
export class FarmerComponent implements OnInit {

  products = [
    { id: 1, name: "eggplant", img: "eggplant.jpg", type: "vegetable" },
    { id: 2, name: "tomato", img: "tomato.jpg", type: "vegetable" },
    { id: 3, name: "garlic", img: "garlic.jpg", type: "vegetable" },
    { id: 4, name: "hot pepper", img: "hotpepper.jpg", type: "vegetable" },
    { id: 5, name: "green pease", img: "pease.jpg", type: "vegetable" },
    { id: 6, name: "cauliflower", img: "cauliflower.jpg", type: "vegetable" },
    { id: 7, name: "pumpkin", img: "pumpkin.jpg", type: "vegetable" },
    { id: 8, name: "radish", img: "radish.jpg", type: "vegetable" },
    { id: 9, name: "potato", img: "potatoes.jpg", type: "vegetable" },
    { id: 10, name: "carrot", img: "carrots.jpg", type: "vegetable" },
    { id: 11, name: "onion", img: "onion.jpg", type: "vegetable" },
    { id: 12, name: "strawberry", img: "strawberries.jpg", type: "fruit" },
    { id: 13, name: "watermelon", img: "watermelon.jpg", type: "fruit" },
    { id: 14, name: "plums", img: "plums.jpg", type: "fruit" },
    { id: 15, name: "pears", img: "pears.jpg", type: "fruit" },
    { id: 16, name: "peaches", img: "peaches.jpg", type: "fruit" },
    { id: 17, name: "apples", img: "apples.jpg", type: "fruit" },
    { id: 18, name: "cherries", img: "cherries.jpg", type: "fruit" },
    { id: 19, name: "salad leaves", img: "salad.jpg", type: "leafy" },
    { id: 20, name: "parsley", img: "parsley.jpg", type: "leafy" },
    { id: 21, name: "beans", img: "beans.jpg", type: "vegetable" },
    { id: 22, name: "beet", img: "beet.jpeg", type: "vegetable" },
    { id: 23, name: "leaf cabbage", img: "leafcabbage.jpg", type: "leafy" },
    { id: 24, name: "raspberry", img: "raspberry.jpg", type: "fruit" },
    { id: 25, name: "lovage", img: "lovage.jpg", type: "leafy" },
    { id: 26, name: "blackberries", img: "blackberries.jpg", type: "fruit" },
    { id: 27, name: "celery", img: "celery.jpg", type: "leafy" },
    { id: 28, name: "cherry", img: "cherry.jpg", type: "fruit" }, // visini
    { id: 29, name: "white cherries", img: "whitecherries.png", type: "fruit" },
    { id: 30, name: "green onions", img: "greenonions.png", type: "leafy" },
    { id: 31, name: "spinach", img: "spinach.jpg", type: "leafy" },
    { id: 32, name: "black currant", img: "blackcurrant.jpg", type: "fruit" },
    { id: 33, name: "white cabbage", img: "whitecabbage.jpg", type: "vegetable" },
    { id: 34, name: "pepper", img: "pepper.jpg", type: "vegetable" },
    { id: 35, name: "zucchini", img: "zucchini.jpg", type: "vegetable" },
    { id: 36, name: "dill", img: "dill.jpg", type: "leafy" },
    { id: 37, name: "goose berries", img: "gooseberries.jpg", type: "fruit" },
    { id: 38, name: "cucumbers", img: "cucumbers.jpeg", type: "fruvegetableit" },
    { id: 39, name: "red peppers", img: "redpeppers.jpg", type: "vegetable" },
    { id: 40, name: "pencil beans", img: "pencilbeans.jpg", type: "vegetable" },
    { id: 41, name: "parsley root", img: "parsleyroot.jpg", type: "vegetable" },
    { id: 42, name: "quince", img: "quince.jpeg", type: "fruit" },
    { id: 43, name: "sorrel", img: "sorrel.jpg", type: "leafy" },
    { id: 44, name: "chard", img: "chard.png", type: "leafy" },
    { id: 45, name: "maize", img: "maize.jpg", type: "vegetable" },
    { id: 46, name: "leek", img: "leek.jpg", type: "leafy" },
    { id: 47, name: "black radish", img: "blackradish.png", type: "vegetable" },
    { id: 48, name: "arugula", img: "arugula.jpg", type: "leafy" },
    { id: 49, name: "red currant", img: "redcurrant.png", type: "fruit" },
    { id: 50, name: "haricot vert", img: "haricotvert.jpg", type: "vegetable" },
    { id: 51, name: "kohlrabi", img: "kohlrabi.jpg", type: "vegetable" },
    { id: 52, name: "chickpeas", img: "chickpeas.jpg", type: "vegetable" },
    { id: 53, name: "nuts", img: "nuts.jpg", type: "fruit" }
  ];

  productOffer: FormGroup; newProductOffer: FormGroup;
  offerDetails: FormArray;
  availableOffers = [];
  steps = [];
  checkoutBox = [];
  offerDataInlineEdit: {price?: number, quantity?: string, note?: string, quantityType?: string} = {}; editingOfferId = -1;

  counter = 0;
  selectedProduct = null;

  @ViewChild('stepper') stepper;

  constructor(private offerService: OfferService,
              private formBuilder: FormBuilder,
              private tokenStorage: TokenStorageService,
              private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.productOffer = this.formBuilder.group({
      'product': [''],
      'quantity': ['', Validators.compose([Validators.required])],
      'quantityType': ['', Validators.compose([Validators.required])],
      'price': [''],
      'note': ['']
    });
    this.offerService.getOffers(this.tokenStorage.getUser()).subscribe(
      data => {
        if (data.message != "Not found.") {
          this.availableOffers = data;
          this.availableOffers.forEach((offer, index) => {
            if (offer.archived == false || !offer.archived) {
              this.counter++;
            }
            let obj = this.products.findIndex(obj => obj.name == offer.productName);
            if (obj > -1) {
              this.availableOffers[index].img = this.products[obj].img;
            }
          });
        }
      }
    );
  }

  /*
  getForms(): Array<any> {
    let arr = [];
    for (let index = 0; index < this.products.length; index++) {
      arr.push(
        this.formBuilder.group({
          'product': ['', Validators.compose([Validators.required])],
          'quantity': ['', Validators.compose([Validators.required])],
          'quantityType': ['', Validators.compose([Validators.required])],
          'price': ['', Validators.compose([Validators.required])],
          'note': ['']
        })
      );
    }
    return arr;
  }
  */

  submit() {
    this.checkoutBox.forEach(offer_data => {
      let data = offer_data;
      data['user'] = this.tokenStorage.getUser();
      data['createdBy'] = this.tokenStorage.getUser();
      this.offerService.createOffer(data).subscribe(
        data => {
          this.availableOffers.unshift(data);
          let obj = this.products.findIndex(obj => obj.name == data.productName);
          if (obj > -1) {
            this.availableOffers[0].img = this.products[obj].img;
          } else {
            this.availableOffers[0].img = "unknown.jpg";
          }
          let snackBarRef = this._snackBar.open("Product added", "Undo", {
            duration: 3500,
          });
          snackBarRef.onAction().subscribe(() => {
            this.deleteOffer(data._id);
          });
          (<FormArray>this.productOffer.get('offerDetails')).reset();
        },
        err => {
          console.log(err.error.message);
        }
      );
    });
    
    this.stepper.selectedIndex = 7;
    this.checkoutBox = [];
    this.steps = [];
  }

  submitNew() {
    let data = this.newProductOffer.value;
    data['user'] = this.tokenStorage.getUser();
    data['createdBy'] = this.tokenStorage.getUser();

    this.offerService.createOffer(data).subscribe(
      data => {
        this.availableOffers.unshift(data);
        let obj = this.products.findIndex(obj => obj.name == data.productName);
        if (obj > -1) {
          this.availableOffers[0].img = this.products[obj].img;
        } else {
          this.availableOffers[0].img = "unknown.jpg";
        }
        let snackBarRef = this._snackBar.open("Product added", "Undo", {
          duration: 3500,
        });
        snackBarRef.onAction().subscribe(() => {
          this.deleteOffer(data._id);
        });
        (<FormArray>this.productOffer.get('offerDetails')).reset();
      },
      err => {
        console.log(err.error.message);
      }
    );
  }

  deleteOffer(offerId) {
    this.offerService.deleteOffer(offerId).subscribe(
      data => {
        let obj = this.availableOffers.findIndex(obj => obj._id == offerId);
        if (obj > -1) {
          this.availableOffers.splice(obj, 1);
        }
      },
      err => {
        console.log(err.error.message);
      }
    );
  }

  addProduct() {
    if (!this.productOffer.valid || !this.selectedProduct) {
      return ;
    }
    let data = this.productOffer.value;
    this.checkoutBox.push({
      product: (this.selectedProduct == 'new') ? data.product : this.selectedProduct,
      quantity: data.quantity,
      quantityType: data.quantityType,
      price: data.price || 0,
      note: data.note,
    });
    this.selectStep(6);
    this.productOffer.reset();
    this.selectedProduct = null;
  }

  removeProduct(index) {
    this.checkoutBox.splice(index, 1);
    if (!this.checkoutBox.length) {
      this.stepper.selectedIndex = 0;
      this.steps = [];
    }
  }

  addAnotherItem() {
    this.stepper.selectedIndex = 0;
    this.steps = [];
  }
  
  onProductSelect(productName) {
    this.selectedProduct = productName;
    this.stepper.selectedIndex = 5;
    this.steps.push(5);
  }

  inlineEditOffer(offerId) {
    this.editingOfferId = offerId;

    let index = this.availableOffers.findIndex(obj => obj._id == offerId);
    this.offerDataInlineEdit.price = this.availableOffers[index].price;
    this.offerDataInlineEdit.quantity = this.availableOffers[index].quantity;
    this.offerDataInlineEdit.quantityType = this.availableOffers[index].quantityType;
    this.offerDataInlineEdit.note = this.availableOffers[index].note;
  }

  saveOfferEdits(offerId) {
    this.editingOfferId = -1;
    
    this.offerService.updateOffer(offerId, {
        price: this.offerDataInlineEdit.price || 0,
        quantity: this.offerDataInlineEdit.quantity,
        quantityType: this.offerDataInlineEdit.quantityType,
        note: this.offerDataInlineEdit.note
    }).subscribe(
      data => {
        let obj = this.availableOffers.findIndex(obj => obj._id == offerId);
        this.availableOffers[obj].price = data.price;
        this.availableOffers[obj].quantity = data.quantity;
        this.availableOffers[obj].quantityType = data.quantityType;
        this.availableOffers[obj].note = data.note;
        let snackBarRef = this._snackBar.open("Order edited!", "Undo", {
          duration: 4500,
        });
      }
    );
  }

  duplicateOffer(offerId) {
    this.selectStep(5);

    let index = this.availableOffers.findIndex(obj => obj._id == offerId);
    this.selectedProduct = this.availableOffers[index].productName;
    this.productOffer.patchValue({
      quantity: this.availableOffers[index].quantity,
      quantityType: this.availableOffers[index].quantityType,
      price: this.availableOffers[index].price,
      note: this.availableOffers[index].note
    });
  }

  selectStep(index, new_product = false) {
    this.stepper.selectedIndex = index;
    this.steps.push(index);
    if (new_product)
      this.selectedProduct = 'new';
  }

  selectPrevStep() {
    this.steps.pop();
    if (this.steps.length > 0)
      this.stepper.selectedIndex = this.steps[this.steps.length - 1];
    else
      this.stepper.selectedIndex = 0;
  }

  stringAsDate(dateStr: string) {
    return new Date(dateStr);
  }

}
