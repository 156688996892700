import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

// const ITEMS_API = 'http://localhost:3000/api/items/';
const ITEMS_API = 'https://app.farms.md/api/items/';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class ItemsService {
  constructor(private http: HttpClient) { }

  getItems(filter = {}): Observable<any> {
    return this.http.get(ITEMS_API, {
      params: filter
    });
  }

  createItem(item): Observable<any> {
    return this.http.post(ITEMS_API, {
      name: item.name,
      type: item.type,
      picture: item.picture
    }, httpOptions);
  }

}