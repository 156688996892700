import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormArray, Validators, FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OfferService } from 'src/app/services/offers.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

export interface farmer {
  username: string;
  name: string;
}

@Component({
  selector: 'app-coordinator',
  templateUrl: './coordinator.component.html',
  styleUrls: ['./coordinator.component.scss']
})

export class CoordinatorComponent implements OnInit {

  products = [
    { id: 1, name: "eggplant", img: "eggplant.jpg" },
    { id: 2, name: "tomato", img: "tomato.jpg" },
    { id: 3, name: "garlic", img: "garlic.jpg" },
    { id: 4, name: "hot pepper", img: "hotpepper.jpg" },
    { id: 5, name: "green pease", img: "pease.jpg" },
    { id: 6, name: "cauliflower", img: "cauliflower.jpg" },
    { id: 7, name: "pumpkin", img: "pumpkin.jpg" },
    { id: 8, name: "radish", img: "radish.jpg" },
    { id: 9, name: "potato", img: "potatoes.jpg" },
    { id: 10, name: "carrot", img: "carrots.jpg" },
    { id: 11, name: "onion", img: "onion.jpg" },
    { id: 12, name: "strawberry", img: "strawberries.jpg" },
    { id: 13, name: "watermelon", img: "watermelon.jpg" },
    { id: 14, name: "plums", img: "plums.jpg" },
    { id: 15, name: "pears", img: "pears.jpg" },
    { id: 16, name: "peaches", img: "peaches.jpg" },
    { id: 17, name: "apples", img: "apples.jpg" },
    { id: 18, name: "cherries", img: "cherries.jpg" },
    { id: 19, name: "salad leaves", img: "salad.jpg" },
    { id: 20, name: "parsley", img: "parsley.jpg" },
    { id: 21, name: "beans", img: "beans.jpg" },
    { id: 22, name: "beet", img: "beet.jpeg" },
    { id: 23, name: "leaf cabbage", img: "leafcabbage.jpg" },
    { id: 24, name: "raspberry", img: "raspberry.jpg" },
    { id: 25, name: "lovage", img: "lovage.jpg" },
    { id: 26, name: "blackberries", img: "blackberries.jpg" },
    { id: 27, name: "celery", img: "celery.jpg" },
    { id: 28, name: "cherry", img: "cherry.jpg" }, // visini
    { id: 29, name: "white cherries", img: "whitecherries.png" },
    { id: 30, name: "green onions", img: "greenonions.png" },
    { id: 31, name: "spinach", img: "spinach.jpg" },
    { id: 32, name: "black currant", img: "blackcurrant.jpg" },
    { id: 33, name: "white cabbage", img: "whitecabbage.jpg" },
    { id: 34, name: "pepper", img: "pepper.jpg" },
    { id: 35, name: "zucchini", img: "zucchini.jpg" },
    { id: 36, name: "dill", img: "dill.jpg" },
    { id: 37, name: "goose berries", img: "gooseberries.jpg" },
    { id: 38, name: "cucumbers", img: "cucumbers.jpeg" },
    { id: 39, name: "red peppers", img: "redpeppers.jpg" },
    { id: 40, name: "pencil beans", img: "pencilbeans.jpg" },
    { id: 41, name: "parsley root", img: "parsleyroot.jpg" },
    { id: 42, name: "quince", img: "quince.jpeg" },
    { id: 43, name: "sorrel", img: "sorrel.jpg" },
    { id: 44, name: "chard", img: "chard.png" },
    { id: 45, name: "maize", img: "maize.jpg" },
    { id: 46, name: "leek", img: "leek.jpg" },
    { id: 47, name: "black radish", img: "blackradish.png" },
    { id: 48, name: "arugula", img: "arugula.jpg" },
    { id: 49, name: "red currant", img: "redcurrant.png" },
    { id: 50, name: "haricot vert", img: "haricotvert.jpg" },
    { id: 51, name: "kohlrabi", img: "kohlrabi.jpg" },
    { id: 52, name: "chickpeas", img: "chickpeas.jpg" },
    { id: 53, name: "nuts", img: "nuts.jpg" }
  ];

  productOffer: FormGroup;
  offerDetails: FormArray;
  farmerControl = new FormControl();
  filteredOptions: Observable<farmer[]>;
  availableOffers = [];
  groupedOffers = [];
  awaitingOffers = [];
  farmers = [];
  farmersInterf = [];
  counter = 0;
  selectedProduct = null;

  constructor(private offerService: OfferService,
              private formBuilder: FormBuilder,
              private tokenStorage: TokenStorageService,
              private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.productOffer = this.formBuilder.group({
      'farmer': [''],
      'product': [''],
      'quantity': ['', Validators.compose([Validators.required])],
      'quantityType': ['', Validators.compose([Validators.required])],
      'price': ['', Validators.compose([Validators.required])],
      'note': ['']
    });
    this.offerService.getOffers(this.tokenStorage.getUser()).subscribe(
      data => {
        if (data.message != "Not found.") {
          this.availableOffers = data;
          this.availableOffers = this.availableOffers.filter(option => (option.requestedAmount > 0 && option.requestedAmount !== 'undefined' && option.received != true));
          this.availableOffers.forEach((offer, index) => {
            if (offer.archived == false || !offer.archived) {
              this.counter++;
            }
            if (offer.requestedAmount && !offer.archived) {
              this.awaitingOffers.push(offer);
            }
            let obj = this.products.findIndex(obj => obj.name == offer.productName);
            if (obj > -1) {
              this.availableOffers[index].img = this.products[obj].img;
            } 
          });
          
          
          let temp = {};  
          this.availableOffers.forEach(offer => {
            let date = offer.createdAt.slice(0, 10);
            if (!temp[date])
              temp[date] = {};
            if (!temp[date][offer.user.username])
              temp[date][offer.user.username] = [];
            if (offer.archived == false || !offer.archived)
              temp[date][offer.user.username].push(offer);
          });
          for (let date in temp) {
            for (let farmer in temp[date]) {
              this.groupedOffers.push(temp[date][farmer]);
            }
          }
          
        }
      }
    );
    this.offerService.getFarmers().subscribe(
      data => {
        if (data.message != "Not found.") {
          this.farmers = data;
          this.farmers.sort((a,b) => (a.name.trim() > b.name.trim()) ? 1 : ((b.name.trim() > a.name.trim()) ? -1 : 0));
          this.filteredOptions = this.farmerControl.valueChanges
          .pipe(
            startWith(''),
            map(farmer => this._filter(farmer)),
          );
        }
      }
    );
    this.farmerControl.valueChanges.subscribe(value => {
      this.productOffer.get('farmer').setValue(value);
    });
  }

  getForms(): Array<any> {
    let arr = [];
    for (let index = 0; index < this.products.length; index++) {
      arr.push(
        this.formBuilder.group({
          'product': ['', Validators.compose([Validators.required])],
          'quantity': ['', Validators.compose([Validators.required])],
          'quantityType': ['', Validators.compose([Validators.required])],
          'price': ['', Validators.compose([Validators.required])],
          'note': ['']
        })
      );
    }
    return arr;
  }

  submit(index: number) {
    if (!this.productOffer.valid || !this.selectedProduct) {
      for (const key of Object.keys(this.productOffer.controls)) {
        if (this.productOffer.controls[key].invalid) {
          console.log(key + " is invalid");
        }
      }
      return ;
    }
    let data = this.productOffer.value;

    if (this.selectedProduct != 'new') {
      data.product = this.selectedProduct;
    }
    data['createdBy'] = this.tokenStorage.getUser();
    data['user'] = (this.productOffer.get('farmer')).value;

    this.offerService.createOffer(data).subscribe(
      data => {
        this.availableOffers.unshift(data);
        let obj = this.products.findIndex(obj => obj.name == data.productName);
        if (obj > -1) {
          this.availableOffers[0].img = this.products[obj].img;
        } else {
          this.availableOffers[0].img = "unknown.jpg";
        }
        let snackBarRef = this._snackBar.open("Product added", "Undo", {
          duration: 3500,
        });
        snackBarRef.onAction().subscribe(() => {
          this.deleteOffer(data._id);
        });
      },
      err => {
        console.log(err.error.message);
      }
    );
  }

  deleteOffer(offerId) {
    this.offerService.deleteOffer(offerId).subscribe(
      data => {
        let obj = this.availableOffers.findIndex(obj => obj._id == offerId);
        if (obj > -1) {
          this.availableOffers.splice(obj, 1);
        }
      },
      err => {
        console.log(err.error.message);
      }
    );
  }

  updateInlineQuantity(offerId, value) {
    if (value == null) { return; }
    this.offerService.updateOffer(offerId, {quantity: value}).subscribe(
      data => {
        let obj = this.awaitingOffers.findIndex(obj => obj._id == offerId);
        this.awaitingOffers[obj].quantity = value;
        let snackBarRef = this._snackBar.open("Order edited!", '', {
          duration: 4500,
        });
      }
    );
  }

  updateInlinePrice(offerId, value) {
    if (value == null) { return; }
    this.offerService.updateOffer(offerId, {price: value}).subscribe(
      data => {
        let obj = this.awaitingOffers.findIndex(obj => obj._id == offerId);
        this.awaitingOffers[obj].price = value;
       let snackBarRef = this._snackBar.open("Order edited!", '', {
        duration: 4500,
      });
      }
    );
  }
  
  setReceived(offerId) {
    this.offerService.updateOffer(offerId, {received: true}).subscribe(
      data => {
        let obj = this.awaitingOffers.findIndex(obj => obj._id == offerId);
        if (obj > -1) {
          this.availableOffers.splice(obj, 1);
        }
        let snackBarRef = this._snackBar.open("Order updated!", '', {
          duration: 4500,
        });
      }
    );
  }
  
  onProductSelect(productName) {
    this.selectedProduct = productName;
    console.log(this.groupedOffers);
  }

  stringAsDate(dateStr: string) {
    return new Date(dateStr);
  }

  getTotalCost(index) {
    let sum = 0;
    for (let i = 0; i < this.groupedOffers[index].length; i++) {
      sum += this.groupedOffers[index][i].quantity * this.groupedOffers[index][i].price;
    }

    return sum || 0;
  }

  private _filter(value: string): farmer[] {
    const filterValue = value.toLowerCase();
    return this.farmers.filter(option => option.name.toLowerCase().startsWith(filterValue));
  }

}
