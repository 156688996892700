<h1 mat-dialog-title>New item</h1>
<div mat-dialog-content>
    <form [formGroup]="itemForm" (ngSubmit)="createItem(itemForm.value)">
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Nume</mat-label>
            <input matInput formControlName="name">
        </mat-form-field>
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Categorie</mat-label>
            <mat-select formControlName="type">
                <mat-option value="vegetable">
                    Legume
                </mat-option>
                <mat-option value="fruit">
                    Fructe
                </mat-option>
                <mat-option value="leafy">
                    Verdeturi
                </mat-option>
            </mat-select>
        </mat-form-field>
        <input (change)="getFile($event)" placeholder="Picture" type="file" required>
        <div class="width-100" align="end">
            <button type="submit" mat-button mat-raised-button color="primary">{{ 'Inventory.sms.send' | translate }}</button>
        </div>
    </form>
</div>