import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

const CRUD_API = 'https://app.farms.md/api/sms/';
const USERS_API = 'https://app.farms.md/api/users/';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class SMSService {
  constructor(private http: HttpClient) { }

  getSMSes(): Observable<any> {
    // let params = new HttpParams().set("username", user.username);
    // return this.http.get(CRUD_API, {params: params});
    return this.http.get(CRUD_API);
  }

  getAllFarmers(): Observable<any> {
    return this.http.get(USERS_API + 'farmer');
  }

  createSMS(sms): Observable<any> {
    return this.http.post(CRUD_API, {
      createdBy: sms.createdBy,
      to: sms.to,
      message: sms.message,
      saveLocal: sms.saveLocal
    }, httpOptions);
  }

  getBalance(): Observable<any> {
      return this.http.get(CRUD_API + 'balance/');
  }

  /*
  deleteOffer(offerId) {
    return this.http.delete(CRUD_API, {
      params: {
        offerId: offerId
      }
    });
  }

  archiveOffer(id): Observable<any> {
    return this.http.put(CRUD_API, {
      offerId: id,
      archive: true
    }, httpOptions);
  }

  orderOffer(id, amount): Observable<any> {
    return this.http.put(CRUD_API, {
      offerId: id,
      amount: amount
    }, httpOptions);
  }

  updateOffer(id, data): Observable<any> {
    data.offerId = id;
    return this.http.put(CRUD_API, data, httpOptions);
  }
  */
}