<mat-tab-group>
  <mat-tab label="{{ 'Coordinator.Offers' | translate }}">
    <div class="coordinator-div" fxLayout="column" fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="space-around start" fxLayoutAlign="start center">
      <div class="prod-list" fxFlex.gt-sm="35%" fxFlex="100%">
        <mat-accordion [multi]="false">
          <form [formGroup]="productOffer">
            <div>
            <mat-form-field class="width-100 select-control" appearance="fill">
              <mat-label>{{ 'Coordinator.Farmer' | translate }}</mat-label>
              <input type="text"
                    placeholder="{{ 'Coordinator.Farmer' | translate }}"
                    matInput
                    [formControl]="farmerControl"
                    [matAutocomplete]="auto">
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let farmer of filteredOptions | async" [value]="farmer.username">
                  {{farmer.name}}
                </mat-option>
              </mat-autocomplete>
              <!-- <mat-label>{{ 'Coordinator.Farmer' | translate }}</mat-label>
              <mat-select formControlName="farmer">
                <mat-option *ngFor="let farmer of farmers" value="{{ farmer.username }}">{{farmer.name}}</mat-option>
              </mat-select> -->
            </mat-form-field>
            <div fxLayout="row wrap" fxLayoutAlign="space-between stretch">
              <mat-card fxFlex="31%" *ngFor="let product of products" (click)="onProductSelect(product.name)" [ngClass]="{'card-selected mat-elevation-z4': selectedProduct == product.name}">
                <span [matTooltip]="selectedProduct == product.name" class="selection">
                  <mat-icon>check_circle</mat-icon>
                </span>
                <img mat-card-image class="product-image" src="../../../assets/img/products/{{product.img}}">
                <div class="mat-text">
                  <p>{{ product.name | translate }}</p>
                </div>
              </mat-card>
              <mat-card fxFlex="31%" (click)="onProductSelect('new')" [ngClass]="{'card-selected mat-elevation-z4': selectedProduct == 'new'}">
                <span [matTooltip]="selectedProduct == 'new'" class="selection">
                  <mat-icon>check_circle</mat-icon>
                </span>
                <!-- <img mat-card-image class="product-image" src="../../../assets/img/products/{{product.img}}"> -->
                <mat-icon class="mat-24 product-image">add</mat-icon>
                <div class="mat-text">
                  <p>{{ 'Coordinator.addnew' | translate }}</p>
                </div>
              </mat-card>
            </div>
            <mat-form-field *ngIf="selectedProduct == 'new'" class="width-100" appearance="fill">
              <mat-label>{{ 'Coordinator.Name' | translate }}</mat-label>
              <input matInput formControlName="product" type="text">
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>{{ 'Coordinator.Quantity' | translate }}</mat-label>
              <input matInput formControlName="quantity" type="text">
            </mat-form-field >
            <mat-button-toggle-group value="kg" aria-label="qtype" class="qtype" formControlName="quantityType">
              <mat-button-toggle value="pcs">{{ 'pcs' | translate }}</mat-button-toggle>
              <mat-button-toggle value="kg">{{ 'kg' | translate }}</mat-button-toggle>
            </mat-button-toggle-group>
            <mat-form-field class="width-100" appearance="fill">
              <mat-label>{{ 'Coordinator.Price' | translate }}</mat-label>
              <input matInput formControlName="price" type="number">
            </mat-form-field>
            <mat-form-field class="width-100" appearance="fill">
              <mat-label>{{ 'Coordinator.Notes' | translate }}</mat-label>
              <textarea matInput formControlName="note"></textarea>
            </mat-form-field>
            <div fxLayout="row" fxLayoutAlign="end center">
              <button mat-button (click)="submit(-1)" mat-raised-button color="primary">{{ 'Coordinator.Add' | translate }}</button>
            </div>
            <!-- <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title fxLayout="row" fxLayoutAlign="start center">
                  <mat-icon class="mat-24 product-image">add</mat-icon>
                  <img src="../../../assets/img/products/pepper.jpg" class="product-image" />
                  <mat-form-field>
                    <mat-label>Name</mat-label>
                    <input matInput formControlName="product" type="text">
                  </mat-form-field>
                </mat-panel-title>
              </mat-expansion-panel-header>
                <mat-form-field>
                  <mat-label>Quantity</mat-label>
                  <input matInput formControlName="quantity" type="text">
                </mat-form-field>
                <mat-button-toggle-group value="kg" aria-label="qtype" class="qtype" formControlName="quantityType">
                  <mat-button-toggle value="pcs">Pcs</mat-button-toggle>
                  <mat-button-toggle value="kg">Kg</mat-button-toggle>
                </mat-button-toggle-group>
                <mat-form-field>
                  <mat-label>Price</mat-label>
                  <input matInput formControlName="price" type="number">
                </mat-form-field>
                <mat-form-field>
                  <mat-label>Notes</mat-label>
                  <textarea matInput formControlName="note"></textarea>
                </mat-form-field>
                <div fxLayout="row" fxLayoutAlign="end center">
                  <button mat-button (click)="submit(-1)" mat-raised-button color="primary">Add</button>
                </div>
            </mat-expansion-panel> -->
            <!-- <mat-expansion-panel formArrayName="offerDetails" *ngFor="let product of productOffer.get('offerDetails')['controls']; let i = index">
              <mat-expansion-panel-header>
                <mat-panel-title fxLayout="row" fxLayoutAlign="start center">
                  <img src="../../../assets/img/products/{{products[i].img}}" class="product-image" />
                  <span>{{ products[i].name }}</span>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div [formGroupName]="i">
                <input type="hidden" formControlName="product" [ngModel]="products[i].name">
                <mat-form-field>
                  <mat-label>Quantity</mat-label>
                  <input matInput formControlName="quantity" type="text">
                </mat-form-field>
                <mat-button-toggle-group value="kg" aria-label="qtype" class="qtype" formControlName="quantityType">
                  <mat-button-toggle value="pcs">Pcs</mat-button-toggle>
                  <mat-button-toggle value="kg">Kg</mat-button-toggle>
                </mat-button-toggle-group>
                <mat-form-field>
                  <mat-label>Price</mat-label>
                  <input matInput formControlName="price" type="number">
                </mat-form-field>
                <mat-form-field>
                  <mat-label>Notes</mat-label>
                  <textarea matInput formControlName="note"></textarea>
                </mat-form-field>
                <div fxLayout="row" fxLayoutAlign="end center">
                  <button mat-button (click)="submit(i)" mat-raised-button color="primary">Add</button>
                </div>
              </div>
            </mat-expansion-panel> -->
          </div>
          </form>
        </mat-accordion>
      </div>
      <div class="offers" fxFlex.gt-sm="35%" fxFlex="100%">
        <div class="available-offers">
          <mat-card class="offer" *ngFor="let offer of availableOffers" [class.green]="offer.requestedAmount">
            <mat-chip-list *ngIf="offer.requestedAmount" aria-label="Fish selection" fxLayoutAlign="end center">
              <!-- <mat-chip [innerHTML]="'Coordinator.Pleasebringmessage' | translate:{'amount': offer.requestedAmount, 'type': offer.quantityType | translate }"></mat-chip>  -->
              <!-- <mat-chip>{{ 'Coordinator.Pleasebringmessage' | translate:{'amount': offer.requestedAmount, 'type': offer.quantityType} }}</mat-chip> -->
            </mat-chip-list>
            <mat-grid-list [class.blured]="offer.archived" cols="3" rowHeight="30px">
              <mat-grid-tile
                  [colspan]="1"
                  [rowspan]="5">
                <img [src]="offer.img != null ? ('../../../assets/img/products/' + offer.img) : ('../../../assets/img/products/unknown.jpg')">
              </mat-grid-tile>
              <mat-grid-tile
                  [colspan]="2"
                  [rowspan]="1">
                <span>{{ 'Coordinator.Product' | translate }}: {{offer.productName | translate }}</span>
              </mat-grid-tile>
              <mat-grid-tile
                  [colspan]="2"
                  [rowspan]="1">
                <span [satPopoverAnchor]="p" (click)="p.open()">{{ 'Coordinator.Quantity' | translate }}: {{offer.quantity}} {{ offer.quantityType | translate }} &nbsp;<mat-icon class="inline-icon" aria-hidden="false">edit</mat-icon></span>
              </mat-grid-tile>
              <mat-grid-tile
                  [colspan]="2"
                  [rowspan]="1">
                <span [satPopoverAnchor]="p2" (click)="p2.open()">{{ 'Coordinator.Price' | translate }}: {{offer.price}} {{ 'lei' | translate }} &nbsp;<mat-icon class="inline-icon" aria-hidden="false">edit</mat-icon></span>
              </mat-grid-tile>
              <mat-grid-tile
                  [colspan]="2"
                  [rowspan]="1" *ngIf="offer.note">
                <span>{{ 'Coordinator.Notes' | translate }}: {{offer.note}}</span>
              </mat-grid-tile>
              <mat-grid-tile
                  [colspan]="2"
                  [rowspan]="1">
                <span>{{ 'Coordinator.dateadded' | translate }}: {{ stringAsDate(offer.createdAt) | date:'dd/MM/yy HH:mm' }}</span>
              </mat-grid-tile>
            </mat-grid-list>
            <mat-card-actions fxLayout="row" fxLayoutAlign="end center" class="m-0 mt-1">
              <button mat-stroked-button color="primary" (click)="setReceived(offer._id)">{{ 'Coordinator.Received' | translate }}</button>
              <button mat-stroked-button color="warn" (click)="deleteOffer(offer._id)">{{ 'Coordinator.Remove' | translate }}</button>
            </mat-card-actions>
            <sat-popover #p
              hasBackdrop
              horizontalAlign="after"
              (closed)="updateInlineQuantity(offer._id, pinput.value)">
              <div class="form">
                <mat-form-field>
                  <input #pinput matInput placeholder="{{ 'Coordinator.Quantity' | translate }}" [value]="offer.quantity" />
                </mat-form-field>
                <button mat-button (click)="p.close()">{{ 'Coordinator.Save' | translate }}</button>
              </div>
            </sat-popover>
            <sat-popover #p2
              hasBackdrop
              horizontalAlign="after"
              (closed)="updateInlinePrice(offer._id, p2input.value)">
              <div class="form">
                <mat-form-field>
                  <input #p2input matInput placeholder="{{ 'Coordinator.Price' | translate }}" [value]="offer.price" />
                </mat-form-field>
                <button mat-button (click)="p2.close()">{{ 'Coordinator.Save' | translate }}</button>
              </div>
            </sat-popover>
          </mat-card>
          <div *ngIf="availableOffers == [] || !availableOffers.length">
            <h2>{{ 'Coordinator.nooffers' | translate }}</h2>
          </div>
        </div>
      </div>
    </div>
  </mat-tab>
  <mat-tab label="{{ 'Coordinator.Ordered' | translate }}">
    <div fxLayoutAlign="space-around start">
      <div class="available-offers" fxFlex.gt-sm="75%" fxFlex="100%" fxLayoutAlign="space-around start" fxLayout="column">
        <!-- <ng-template *ngIf="awaitingOffers.length"> -->
          <!-- <div *ngFor="let offer of awaitingOffers">
            <mat-card class="offer" *ngIf="offer.archived == false || !offer.archived">
              <div fxLayout.gt-xs="row" fxLayout="column" fxLayoutAlign="center center">
                <div class="offer-li" fxLayoutAlign="space-around center" fxFlex.gt-xs="25%" fxFlex="100%"> 
                  <img fxFlex.xs="50%" matCardImage [src]="offer.img != null ? ('../../../assets/img/products/' + offer.img) : ('../../../assets/img/products/unknown.jpg')">
                </div>
                <div fxLayout="row wrap" fxLayoutAlign.gt-xs="start center" fxLayoutAlign="space-around center" fxFlex.gt-xs="75%" fxFlex="100%">
                  <div class="offer-li" fxFlex.xs="50%">
                    <span>{{ 'Coordinator.Product' | translate }}: {{offer.productName}}</span>
                  </div>
                  <div class="offer-li" fxFlex.xs="50%">
                    <span>{{ 'Coordinator.Farmer' | translate }}: {{offer.user.name}}</span>
                  </div>
                  <div class="offer-li" fxFlex.xs="50%">
                    <span>{{ 'Coordinator.farmerphone' | translate }}: {{offer.user.phone}}</span>
                  </div>
                  <div class="offer-li" fxFlex.xs="50%" [satPopoverAnchor]="p" (click)="p.open()">
                    <span>{{ 'Coordinator.reqquantity' | translate }}: {{offer.requestedAmount}} {{offer.quantityType}} <mat-icon inline="true">create</mat-icon> </span>
                  </div>
                  <div class="offer-li" fxFlex.xs="50%" [satPopoverAnchor]="p2" (click)="p2.open()">
                    <span>{{ 'Coordinator.Price' | translate }}: {{offer.price}} <mat-icon inline="true">create</mat-icon> </span>
                  </div>
                  <div class="offer-li" fxFlex.xs="50%">
                    <span>{{ 'Coordinator.dateadded' | translate }}: {{ stringAsDate(offer.createdAt) | date:'dd/MM/yy HH:mm' }}</span>
                  </div>
                  <sat-popover #p
                      hasBackdrop
                      xAlign="start"
                      yAlign="start"
                      (closed)="updateInlineQuantity(offer._id, $event)">
                    <inline-edit [value]="offer.requestedAmount"></inline-edit>
                  </sat-popover>
                  <sat-popover #p2
                      hasBackdrop
                      xAlign="start"
                      yAlign="start"
                      (closed)="updateInlinePrice(offer._id, $event)">
                    <inline-edit [value]="offer.price"></inline-edit>
                  </sat-popover>
                </div>
              </div>
            </mat-card>
          </div> -->
          <ng-container *ngFor="let group of groupedOffers; let i = index">
            <mat-card class="offer" *ngIf="group[0]">
              <div class="offer-li">
                <span>{{ 'Coordinator.Farmer' | translate }}: {{group[0].user.name}}</span>
              </div>
              <div class="offer-li">
                <span>{{ 'Coordinator.farmerphone' | translate }}: {{group[0].user.phone}}</span>
              </div>
              <div class="offer-li">
                <span>{{ 'Coordinator.dateadded' | translate }}: {{ stringAsDate(group[0].createdAt) | date:'dd/MM/yy HH:mm' }}</span>
              </div>
              <!-- <ng-container *ngFor="let offer of group"> -->
                <table mat-table [dataSource]="group" class="mat-elevation-z8">
                  <ng-container matColumnDef="product">
                    <th mat-header-cell *matHeaderCellDef> {{ 'Coordinator.Product' | translate }} </th>
                    <td mat-cell *matCellDef="let element"> {{element.productName | translate}} </td>
                    <td mat-footer-cell *matFooterCellDef> {{ 'Coordinator.Total' | translate }} </td>
                  </ng-container>
                  <ng-container matColumnDef="quantity">
                    <th mat-header-cell *matHeaderCellDef> {{ 'Coordinator.Quantity' | translate }} </th>
                    <td mat-cell *matCellDef="let element"> {{element.quantity}} {{ element.quantityType | translate }}</td>
                    <!-- <td mat-cell *matCellDef="let element"> 
                      <mat-form-field floatLabel="never">
                        <input matInput placeholder="{{element.quantity}}" [value]="element.quantity" [(ngModel)]="element.quantity">
                      </mat-form-field>
                    </td> -->
                    <td mat-footer-cell *matFooterCellDef> </td>
                  </ng-container>
                  <ng-container matColumnDef="price">
                    <th mat-header-cell *matHeaderCellDef> {{ 'Coordinator.Price' | translate }} </th>
                    <td mat-cell *matCellDef="let element"> {{element.price}} {{ 'lei' | translate }}</td>
                    <!-- <td mat-cell *matCellDef="let element">
                      <mat-form-field floatLabel="never">
                        <input matInput placeholder="{{element.price}}" [value]="element.price" [(ngModel)]="element.price">
                      </mat-form-field>
                    </td> -->
                    <td mat-footer-cell *matFooterCellDef> </td>
                  </ng-container>
                  <ng-container matColumnDef="cost">
                    <th mat-header-cell *matHeaderCellDef> {{ 'Coordinator.Cost' | translate }} </th>
                    <td mat-cell *matCellDef="let element"> {{(element.price * element.quantity) || 0}} {{ 'lei' | translate }}</td>
                    <td mat-footer-cell *matFooterCellDef> {{ getTotalCost(i)}} {{ 'lei' | translate }}</td>
                  </ng-container>
                
                  <tr mat-header-row *matHeaderRowDef="['product', 'quantity', 'price', 'cost']"></tr>
                  <tr mat-row *matRowDef="let row; columns: ['product', 'quantity', 'price', 'cost'];"></tr>
                  <tr mat-footer-row *matFooterRowDef="['product', 'quantity', 'price', 'cost']"></tr>
                </table>
              <!-- </ng-container> -->
            </mat-card>
          </ng-container>
        <!-- </ng-template> -->
        <div *ngIf="awaitingOffers == [] || !awaitingOffers.length">
          <h2>{{ 'Coordinator.noorders' | translate }}</h2>
        </div>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>