import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { first, map, startWith, tap } from 'rxjs/operators';
import { DeliveryService } from 'src/app/services/delivery.service';
import { OfferService } from 'src/app/services/offers.service';
import { ItemsService } from 'src/app/services/items.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { SMSService } from 'src/app/services/sms.service';
import * as XLSX from 'xlsx';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { DatePipe } from '@angular/common';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.scss']
})


export class InventoryComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild("street")
  public streetElementRef: ElementRef;
  products = [
    { id: 1, name: "eggplant", img: "eggplant.jpg" },
    { id: 2, name: "tomato", img: "tomato.jpg" },
    { id: 3, name: "garlic", img: "garlic.jpg" },
    { id: 4, name: "hot pepper", img: "hotpepper.jpg" },
    { id: 5, name: "green pease", img: "pease.jpg" },
    { id: 6, name: "cauliflower", img: "cauliflower.jpg" },
    { id: 7, name: "pumpkin", img: "pumpkin.jpg" },
    { id: 8, name: "radish", img: "radish.jpg" },
    { id: 9, name: "potato", img: "potatoes.jpg" },
    { id: 10, name: "carrot", img: "carrots.jpg" },
    { id: 11, name: "onion", img: "onion.jpg" },
    { id: 12, name: "strawberry", img: "strawberries.jpg" },
    { id: 13, name: "watermelon", img: "watermelon.jpg" },
    { id: 14, name: "plums", img: "plums.jpg" },
    { id: 15, name: "pears", img: "pears.jpg" },
    { id: 16, name: "peaches", img: "peaches.jpg" },
    { id: 17, name: "apples", img: "apples.jpg" },
    { id: 18, name: "cherries", img: "cherries.jpg" },
    { id: 19, name: "salad leaves", img: "salad.jpg" },
    { id: 20, name: "parsley", img: "parsley.jpg" },
    { id: 21, name: "beans", img: "beans.jpg" },
    { id: 22, name: "beet", img: "beet.jpeg" },
    { id: 23, name: "leaf cabbage", img: "leafcabbage.jpg" },
    { id: 24, name: "raspberry", img: "raspberry.jpg" },
    { id: 25, name: "lovage", img: "lovage.jpg" },
    { id: 26, name: "blackberries", img: "blackberries.jpg" },
    { id: 27, name: "celery", img: "celery.jpg" },
    { id: 28, name: "cherry", img: "cherry.jpg" }, // visini
    { id: 29, name: "white cherries", img: "whitecherries.png" },
    { id: 30, name: "green onions", img: "greenonions.png" },
    { id: 31, name: "spinach", img: "spinach.jpg" },
    { id: 32, name: "black currant", img: "blackcurrant.jpg" },
    { id: 33, name: "white cabbage", img: "whitecabbage.jpg" },
    { id: 34, name: "pepper", img: "pepper.jpg" },
    { id: 35, name: "zucchini", img: "zucchini.jpg" },
    { id: 36, name: "dill", img: "dill.jpg" },
    { id: 37, name: "goose berries", img: "gooseberries.jpg" },
    { id: 38, name: "cucumbers", img: "cucumbers.jpeg" },
    { id: 39, name: "red peppers", img: "redpeppers.jpg" },
    { id: 40, name: "pencil beans", img: "pencilbeans.jpg" },
    { id: 41, name: "parsley root", img: "parsleyroot.jpg" },
    { id: 42, name: "quince", img: "quince.jpeg" },
    { id: 43, name: "sorrel", img: "sorrel.jpg" },
    { id: 44, name: "chard", img: "chard.png" },
    { id: 45, name: "maize", img: "maize.jpg" },
    { id: 46, name: "leek", img: "leek.jpg" },
    { id: 47, name: "black radish", img: "blackradish.png" },
    { id: 48, name: "arugula", img: "arugula.jpg" },
    { id: 49, name: "red currant", img: "redcurrant.png" },
    { id: 50, name: "haricot vert", img: "haricotvert.jpg" },
    { id: 51, name: "kohlrabi", img: "kohlrabi.jpg" },
    { id: 52, name: "chickpeas", img: "chickpeas.jpg" },
    { id: 53, name: "nuts", img: "nuts.jpg" }
  ];
  users = [];
  availableOffers = [];
  archivedOffers = [];
  availableSmses = [];
  awaitingOffers = [];
  deliveries = [];
  clients = [];
  counter = 0;
  @ViewChild(FormGroupDirective) formdirective;
  orderForm;
  filteredOptions: Observable<string[]>;
  selectedClient = "";
  smsForm: FormGroup;
  sendingSMS = false;
  amount = [];
  smsBalance = 0;

  arrayBuffer: any;
  uploadItems = []
  file: File;

  pageEvent: PageEvent;
  offers = {
    pageSize: 5,
    currentPage: 0,
    totalSize: 0
  };
  offersArchived = {
    pageSize: 5,
    currentPage: 0,
    totalSize: 0
  };
  usersPagination = {
    pageSize: 10,
    currentPage: 0,
    totalSize: 0
  };

  constructor(private offerService: OfferService,
    private deliveryService: DeliveryService,
    private tokenStorage: TokenStorageService,
    private smsService: SMSService,
    private formBuilder: FormBuilder,
    private _snackBar: MatSnackBar,
    private translate: TranslateService,
    public dialog: MatDialog) { }

  ngOnInit(): void {
    this.offerService.getOffersNotArchived(this.tokenStorage.getUser(), 0, 0).subscribe(
      (data) => {
        if (data.message != "Not found.") {
          this.availableOffers = data;
          this.offers.totalSize = this.availableOffers.length;
          this.offerService.getOffersNotArchived(this.tokenStorage.getUser(), this.offers.currentPage, this.offers.pageSize).subscribe(
            (data) => {
              if (data.message != "Not found.") {
                this.availableOffers = data;
                this.availableOffers.forEach((offer, index) => {
                  if (offer.archived == false || !offer.archived) {
                    this.counter++;
                  }
                  if (offer.requestedAmount && offer.requestedAmount != 0) {
                    this.awaitingOffers.push(offer);
                  }
                  let obj = this.products.findIndex(obj => obj.name == offer.productName);
                  if (obj > -1) {
                    this.availableOffers[index].img = this.products[obj].img;
                  }
                });
              }
            }
          );
        }
      }
    );
    this.offerService.getOffersArchived(this.tokenStorage.getUser(), 0, 0).subscribe(
      (data) => {
        if (data.message != "Not found.") {
          this.archivedOffers = data;
          this.offersArchived.totalSize = this.archivedOffers.length;
          this.offerService.getOffersArchived(this.tokenStorage.getUser(), this.offersArchived.currentPage, this.offersArchived.pageSize).subscribe(
            (data) => {
              if (data.message != "Not found.") {
                this.archivedOffers = data;
                this.archivedOffers.forEach((offer, index) => {
                  let obj = this.products.findIndex(obj => obj.name == offer.productName);
                  if (obj > -1) {
                    this.archivedOffers[index].img = this.products[obj].img;
                  }
                });
              }
            }
          );
        }
      }
    );
    this.deliveryService.getDeliveries({ deliveredTime: null }).subscribe(
      data => {
        if (data.message != "Not found.") {
          this.deliveries = data;
        }
      }
    );
    this.deliveryService.getClients().subscribe(data => {
      this.clients = data;
    });
    this.offerService.getUsers(0, 0).subscribe(
      (data) => {
        if (data.message != "Not found.") {
          this.users = data;
          this.usersPagination.totalSize = this.users.length;
          this.offerService.getUsers(this.usersPagination.currentPage, this.usersPagination.pageSize).subscribe(
            (data) => {
              if (data.message != "Not found.") {
                this.users = data;
              }
              this.users.forEach((user, index) => {
                if (!user.lastname) {
                  this.users[index].lastname = "";
                }
                if (!user.firstname) {
                  this.users[index].firstname = "";
                }
              });
            }
          );
        }
      }
    );
    this.smsService.getSMSes().subscribe(data => {
      if (data.message && data.message != "")
        this.availableSmses = [];
      else
        this.availableSmses = data;
    });
    this.smsService.getBalance().subscribe(data => {
      this.smsBalance = data.balance;
    });
    this.orderForm = this.formBuilder.group({
      'name': ['', Validators.compose([Validators.required])],
      'phone': ['', Validators.compose([Validators.required])],
      'city': ['', Validators.compose([Validators.required])],
      'street': ['', Validators.compose([Validators.required])],
      'number': ['', Validators.compose([Validators.required])],
      'bagWeight': ['', Validators.compose([Validators.required])],
      'bagSize': ['', Validators.compose([Validators.required])]
    });
    this.filteredOptions = this.orderForm.get('name').valueChanges
    .pipe(
      startWith(''),
      map(val => this.filterNames(val))
    );
    this.smsForm = this.formBuilder.group({
      'message': ['', Validators.compose([Validators.required])],
      'to': ['', Validators.compose([Validators.required])]
    });

  }

  ngAfterContentInit() {
    this.orderForm.controls['bagSize'].setValue('Medium');
  }

  public handlePage(e: any) {
    this.offers.currentPage = e.pageIndex;
    this.offers.pageSize = e.pageSize;
    this.offerService.getOffersNotArchived(this.tokenStorage.getUser(), this.offers.currentPage, this.offers.pageSize).subscribe(
      (data) => {
        if (data.message != "Not found.") {
          this.availableOffers = data;
          // this.iterator();
          this.availableOffers.forEach((offer, index) => {
            if (offer.archived == false || !offer.archived) {
              this.counter++;
            }
            if (offer.requestedAmount && offer.requestedAmount != 0) {
              this.awaitingOffers.push(offer);
            }
            let obj = this.products.findIndex(obj => obj.name == offer.productName);
            if (obj > -1) {
              this.availableOffers[index].img = this.products[obj].img;
            }
          });
        }
      }
    );
  }

  public handlePageArchived(e: any) {
    this.offersArchived.currentPage = e.pageIndex;
    this.offersArchived.pageSize = e.pageSize;
    this.offerService.getOffersArchived(this.tokenStorage.getUser(), this.offersArchived.currentPage, this.offersArchived.pageSize).subscribe(
      (data) => {
        if (data.message != "Not found.") {
          this.archivedOffers = data;
          this.archivedOffers.forEach((offer, index) => {
            let obj = this.products.findIndex(obj => obj.name == offer.productName);
            if (obj > -1) {
              this.archivedOffers[index].img = this.products[obj].img;
            }
          });
        }
      }
    );
  }

  public handlePageUsers(e: any) {
    this.usersPagination.currentPage = e.pageIndex;
    this.usersPagination.pageSize = e.pageSize;
    this.offerService.getUsers(this.usersPagination.currentPage, this.usersPagination.pageSize).subscribe(
      (data) => {
        if (data.message != "Not found.") {
          this.users = data;
        }
        this.users.forEach((user, index) => {
          if (!user.lastname) {
            this.users[index].lastname = "";
          }
          if (!user.firstname) {
            this.users[index].firstname = "";
          }
        });
      }
    );
  }

  archive(id) {
    this.offerService.archiveOffer(id).subscribe(
      data => {
        this.offerService.getOffersNotArchived(this.tokenStorage.getUser(), this.offers.currentPage, this.offers.pageSize).subscribe(
          (data) => {
            if (data.message != "Not found.") {
              this.availableOffers = data;
              // this.iterator();
              this.availableOffers.forEach((offer, index) => {
                if (offer.archived == false || !offer.archived) {
                  this.counter++;
                }
                if (offer.requestedAmount && offer.requestedAmount != 0) {
                  this.awaitingOffers.push(offer);
                }
                let obj = this.products.findIndex(obj => obj.name == offer.productName);
                if (obj > -1) {
                  this.availableOffers[index].img = this.products[obj].img;
                }
              });
            }
          }
        );
      }
    );
  }

  order(id, amount) {
    this.offerService.orderOffer(id, amount).subscribe(
      data => {
        let obj = this.availableOffers.findIndex(obj => obj._id == id);
        this.availableOffers[obj].requestedAmount = amount;
        if (amount == 0) {
          let obj2 = this.awaitingOffers.findIndex(obj2 => obj2._id == id);
          this.awaitingOffers.splice(obj2, 1);
          let snackBarRef2 = this._snackBar.open("Order canceled!", "Undo", {
            duration: 3500,
          });
        } else if (amount > 0) {
          this.awaitingOffers.push(this.availableOffers[obj]);
          let snackBarRef = this._snackBar.open("Order added!", "Undo", {
            duration: 4500,
          });
          snackBarRef.onAction().subscribe(() => {
            this.offerService.orderOffer(id, 0).subscribe(data => {
              this.availableOffers[obj].requestedAmount = 0;
              this.awaitingOffers.splice(-1, 1);
              let snackBarRef2 = this._snackBar.open("Order canceled!", "Undo", {
                duration: 3500,
              });
            });
          });
        }
      }
    );
    this.amount = [];
  }

  addOrder(deliveryData: any) {
    deliveryData['createdBy'] = this.tokenStorage.getUser();
    deliveryData['street'] = this.streetElementRef.nativeElement.value;

    if (this.selectedClient == "") {
      this.deliveryService.createClient(deliveryData).subscribe((r) => {
        deliveryData['clientId'] = r._id;
        this.clients.push(r);
        this.deliveryService.createDelivery(deliveryData).subscribe((data) => {
          this.deliveryService.getDeliveries({ deliveredTime: null }).subscribe(
            data => {
              if (data.message != "Not found.") {
                this.deliveries = data;
              }
            }
          );
          let snackBarRef = this._snackBar.open("Order added!", "Undo", {
            duration: 4500,
          });
          snackBarRef.onAction().subscribe(() => {
            this.deleteDelivery(data._id);
          });
        },
          err => {
            console.log(err.error.message);
          });
      });
    } else {
      this.deliveryService.editClient(this.selectedClient, deliveryData).subscribe((r) => {
        deliveryData['clientId'] = r._id;
        let index = this.clients.findIndex(client => client._id === r._id);
        this.clients[index] = r;
        this.deliveryService.createDelivery(deliveryData).subscribe((data) => {
          this.deliveryService.getDeliveries({ deliveredTime: null }).subscribe(
            data => {
              if (data.message != "Not found.") {
                this.deliveries = data;
              }
            }
          );
          let snackBarRef = this._snackBar.open("Order added!", "Undo", {
            duration: 4500,
          });
          snackBarRef.onAction().subscribe(() => {
            this.deleteDelivery(data._id);
          });
        },
          err => {
            console.log(err.error.message);
          });
      });
    }

    this.formdirective.resetForm();
    this.orderForm.reset();
  }

  deleteDelivery(deliveryId) {
    this.deliveryService.deleteDelivery(deliveryId).subscribe(
      data => {
        let obj = this.deliveries.findIndex(obj => obj._id == deliveryId);
        if (obj > -1) {
          this.deliveries.splice(obj, 1);
        }
        let snackBarRef = this._snackBar.open("Order canceled!", "", {
          duration: 3500,
        });
      },
      err => {
        console.log(err.error.message);
      }
    );
  }

  autoSelectionAction(event) {
    let id = event.option.value;
    let index = this.clients.findIndex(client => client._id === id);
    this.orderForm.get('name').setValue(this.clients[index].name);
    this.orderForm.get('phone').setValue(this.clients[index].phone);
    this.orderForm.get('city').setValue(this.clients[index].city);
    this.orderForm.get('street').setValue(this.clients[index].address);

    this.streetElementRef.nativeElement.value = this.clients[index].address;
    this.orderForm.get('number').setValue(this.clients[index].houseNumber);

    this.selectedClient = id;
  }

  googleMapsSelected(event) {
    event.address_components.forEach(element => {
      if (element.types.indexOf("route") != -1) {
        this.streetElementRef.nativeElement.value = element.long_name; //.replace('Strada ','').replace('Bulevardul ','')
      }
      if (element.types.indexOf("locality") != -1) {
        this.orderForm.get('city').setValue(element.long_name);
      }
    });

  }
  googleMapsSelectedLoc(event) {
    // console.log(event);
  }

  sendSMS(smsData: any) {
    this.sendingSMS = true;
    smsData['createdBy'] = this.tokenStorage.getUser();
    let i = 0;
    if (smsData.to == 'all' || smsData.to == 'Everyone' || smsData.to == 'всем' || smsData.to == 'Toți') {
      this.smsService.getAllFarmers().subscribe((res) => {
        // res.forEach(user => {
        //   if (user.phone && user.phone.replace(/\s/g,'').slice(-8).length == 8) {
        //     smsData.to = user.phone.replace(/\s/g,'').slice(-8);
        //     this.smsService.createSMS(smsData).subscribe(res => {
        //       if (i == 0) {
        //         this.availableSmses.unshift(res.lsms);
        //       }
        //       i++;
        //     });
        //     console.log(smsData.to);
        //   }
        // });

        let firstSent = false;
        let that = this;
        let promiseArr = res.map(function (user, index) {
          if (user.phone && user.phone.replace(/\s/g, '').slice(-8).length == 8) {
            smsData.to = user.phone.replace(/\s/g, '').slice(-8);
            smsData.saveLocal = false;
            if (!firstSent) {
              smsData.saveLocal = true;
              firstSent = true;
            }
            return that.smsService.createSMS(smsData).pipe(
              tap(data => {
                i++;
              }),
              first()
            ).toPromise();
          }
          return null;
        });
        Promise.all(promiseArr).then(function (resultsArray) {
          that.smsService.getSMSes().subscribe(data => {
            that.availableSmses = data;
          });
          that.smsService.getBalance().subscribe(data => {
            that.smsBalance = data.balance;
          });
          that._snackBar.open(i + " SMSes sent!", null, {
            duration: 2500,
          });
          that.sendingSMS = false;
        }).catch(function (err) {
          console.log(err);
        })
      });
    }
  }

  incomingfile(event) {
    this.file = event.target.files[0];
  }

  Upload() {
    let fileReader = new FileReader();
    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
      var data = new Uint8Array(this.arrayBuffer);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join("");
      var workbook = XLSX.read(bstr, { type: "binary" });
      var first_sheet_name = workbook.SheetNames[0];
      var worksheet = workbook.Sheets[first_sheet_name];
      console.log(XLSX.utils.sheet_to_json(worksheet, { raw: true }));
      this.uploadItems = XLSX.utils.sheet_to_json(worksheet, { raw: true });
    }
    fileReader.readAsArrayBuffer(this.file);
  }

  addClient(itemsIndex) {
    this.deliveryService.createClient({
      name: this.uploadItems[itemsIndex]['nume'],
      phone: this.uploadItems[itemsIndex]['telefon'],
      city: this.uploadItems[itemsIndex]['oras'],
      street: this.uploadItems[itemsIndex]['strada'],
      number: this.uploadItems[itemsIndex]['numar']
    }).subscribe((r) => {
      this.uploadItems.splice(itemsIndex, 1);
    });
  }

  stringAsDate(dateStr: string) {
    return new Date(dateStr);
  }

  filterNames(val) {
    this.selectedClient = "";
    let arr: any[];
    if (val) {
      arr = this.clients.filter(s => new RegExp(`^${val}`, 'gi').test(s.name));
    } else {
      arr = this.clients;
    }
    return arr;
  }

  exportExcel() {
    let pipe = new DatePipe('ro-RO'); // Use your own locale
    let exported_offers = [];
    this.availableOffers.forEach((offer, index) => {
      if (offer.export_check) {
        exported_offers.push(offer);
      }
    });
    if (!exported_offers.length)
      return;
    const rows = exported_offers.map(row => ({
      Nume: row.user.lastname ?? row.user.name,
      Prenume: row.user.firstname,
      Produs: this.translate.instant(row.productName),
      Cantitate: row.quantity,
      "Tip cantitate": row.quantityType,
      Pret: row.price,
      Note: row.note,
      "Data adaugarii": pipe.transform(row.createdAt, 'short'),
    }));
    const worksheet = XLSX.utils.json_to_sheet(rows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Oferte");
    XLSX.writeFile(workbook, "Oferte.xlsx");
  }

  exportExcelArchived() {
    let pipe = new DatePipe('ro-RO'); // Use your own locale
    let exported_offers = [];
    this.archivedOffers.forEach((offer, index) => {
      if (offer.export_check) {
        exported_offers.push(offer);
      }
    });
    if (!exported_offers.length)
      return;
    const rows = exported_offers.map(row => ({
      Nume: row.user.lastname ?? row.user.name,
      Prenume: row.user.firstname,
      Produs: this.translate.instant(row.productName),
      Cantitate: row.quantity,
      "Tip cantitate": row.quantityType,
      Pret: row.price,
      Note: row.note,
      "Data adaugarii": pipe.transform(row.createdAt, 'short'),
    }));
    const worksheet = XLSX.utils.json_to_sheet(rows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Oferte");
    XLSX.writeFile(workbook, "Oferte.xlsx");
  }

  openDialogNewItem() {
    const dialogRef = this.dialog.open(DialogNewItem, {
      width: '750px'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  updateUser(userId, event) {
    let newData;
    if (!event.target[1].value || !event.target[2].value) {
      newData = {
        "lastname": event.target[1].value,
        "firstname": event.target[2].value,
        "username": event.target[3].value,
        "phone": event.target[4].value
      };
    } else {
      newData = {
        "name": event.target[1].value + " " + event.target[2].value,
        "lastname": event.target[1].value,
        "firstname": event.target[2].value,
        "username": event.target[3].value,
        "phone": event.target[4].value
      };
    }
    console.log(newData);
    this.offerService.updateUser(userId, newData).subscribe(
      resp => {
        this.offerService.getUsers(this.usersPagination.currentPage, this.usersPagination.pageSize).subscribe(
          (data) => {
            if (data.message != "Not found.") {
              this.users = data;
            }
            this.users.forEach((user, index) => {
              if (!user.lastname) {
                this.users[index].lastname = "";
              }
              if (!user.firstname) {
                this.users[index].firstname = "";
              }
            });
          }
        );
        this._snackBar.open("Utilizator actualizat!", null, {
          duration: 2500,
        });
      }
    );
  }

}

@Component({
  selector: 'dialog-new-item',
  templateUrl: 'dialog-new-item.html',
  styleUrls: ['./inventory.component.scss']
})

export class DialogNewItem {
  itemForm: FormGroup;
  constructor(
    public dialogRef: MatDialogRef<DialogNewItem>,
    private formBuilder: FormBuilder,
    private itemsService: ItemsService
  ) {
    
    this.itemForm = this.formBuilder.group({
      'name': ['', Validators.compose([Validators.required])],
      'type': ['', Validators.compose([Validators.required])],
      'picture': ['', Validators.compose([Validators.required])]
    });
   }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getFile(e) {
    let extensionAllowed = { "png": true, "jpeg": true, "jpg": true };
    console.log(e.target.files);
    if (e.target.files[0].size / 1024 / 1024 > 20) {
      alert("File size should be less than 20MB")
      return;
    }
    if (extensionAllowed) {
      var nam = e.target.files[0].name.split('.').pop();
      if (!extensionAllowed[nam]) {
        alert("Please upload " + Object.keys(extensionAllowed) + " file.")
        return;
      }
    }
    this.itemForm.controls["picture"].setValue(e.target.files[0]);
  }

  createItem(itemData: any) {
    console.log(itemData);
    this.itemsService.createItem(itemData).subscribe((r) => {
      console.log(r);
      // this.uploadItems.splice(itemsIndex, 1);
    });
  }
}