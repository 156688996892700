<div class="partner">
  <div class="bags-builder" fxLayout="row wrap" fxLayoutAlign="space-around start">
    <div class="bags-col" fxFlex.gt-xs="40%" fxFlex="90%">
      <form [formGroup]="orderForm" (ngSubmit)="addOrder(orderForm.value)">
        <mat-card class="order-form" fxLayout="row wrap" fxLayoutAlign="space-between">
          <mat-form-field appearance="outline" fxFlex="100%">
            <mat-label><mat-icon>person</mat-icon> {{ 'Partner.Clientname' | translate }}</mat-label>
            <input matInput formControlName="name" type="text" [matAutocomplete]="autoname">
            <mat-autocomplete #autoname="matAutocomplete" (optionSelected)='autoSelectionAction($event)'>
              <mat-option *ngFor="let client of filteredOptions | async" [value]="client._id">
                {{ client.name }}
              </mat-option>
            </mat-autocomplete>
            <!-- <mat-error *ngIf="orderForm.get('name').hasError('required')">Name is <strong>required</strong></mat-error> -->
          </mat-form-field>
          <mat-form-field appearance="outline" fxFlex.gt-sm="48%" fxFlex="100%">
            <mat-label><mat-icon>call</mat-icon> {{ 'Partner.Phone' | translate }}</mat-label>
            <input matInput formControlName="phone" type="text">
          </mat-form-field>
          <mat-form-field appearance="outline" fxFlex.gt-sm="48%" fxFlex="100%">
            <mat-label>{{ 'Partner.City' | translate }}</mat-label>
            <input matInput formControlName="city" type="text">
          </mat-form-field>
          <mat-form-field appearance="outline" fxFlex="100%" fxFlex.gt-sm="72%">
            <mat-label><mat-icon>location_on</mat-icon> {{ 'Partner.Street' | translate }}</mat-label>
            <input matInput formControlName="street" type="text">
          </mat-form-field>
          <mat-form-field appearance="outline" fxFlex="100%" fxFlex.gt-sm="24%">
            <mat-label>Nr / Ap</mat-label>
            <input matInput formControlName="number" type="text">
          </mat-form-field>
          <mat-form-field appearance="outline" fxFlex="48%">
            <mat-label><mat-icon>shopping_bag</mat-icon> {{ 'Partner.Bagweight' | translate }}</mat-label>
            <input matInput formControlName="bagWeight" type="text">
          </mat-form-field>
          <mat-form-field appearance="outline" fxFlex="48%">
            <mat-label>{{ 'Partner.Bagsize' | translate }}</mat-label>
            <mat-select formControlName="bagSize">
              <mat-option value="small">
                {{ 'Partner.Small' | translate }}
              </mat-option>
              <mat-option value="medium">
                {{ 'Partner.Medium' | translate }}
              </mat-option>
              <mat-option value="large">
                {{ 'Partner.Large' | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div class="width-100" align="end">
            <button type="submit" mat-button mat-raised-button color="primary" [disabled]="orderForm.invalid">{{ 'Partner.Addorder' | translate }}</button>
          </div>
        </mat-card>
      </form>
    </div>
    <div class="bags-col" fxFlex.gt-xs="40%" fxFlex="90%">
      <mat-card class="order-card" fxLayout="row wrap" fxLayoutAlign="space-between" *ngFor="let delivery of deliveries">
        <mat-card-content fxLayout="row wrap" fxLayoutAlign="space-between">
          <mat-card [class.mat-elevation-z1]="true" fxFlex.gt-xs="49%">{{ 'Partner.Clientname' | translate }}: {{ delivery.client.name }} </mat-card>
          <mat-card [class.mat-elevation-z1]="true" fxFlex.gt-xs="49%">{{ 'Partner.Phone' | translate }}: {{ delivery.client.phone }} </mat-card>
          <mat-card [class.mat-elevation-z1]="true" fxFlex="100%">{{ 'Partner.City' | translate }}: {{ delivery.client.city }}, Address: {{ delivery.client.address }}, {{ delivery.client.houseNumber}}</mat-card>
          <mat-card [class.mat-elevation-z1]="true">{{ 'Partner.Bagweight' | translate }}: {{ delivery.weight}} | {{ 'Partner.Bagsize' | translate }}: {{ delivery.size }}</mat-card>
          <mat-card [class.mat-elevation-z1]="true">{{ 'Partner.Dateadded' | translate }}: {{ stringAsDate(delivery.createdAt) | date:'dd/MM/yy HH:mm' }}</mat-card>
          <div fxLayout="row" fxLayoutAlign="end center" fxFlex="100%">
            <button type="button" (click)="deleteDelivery(delivery._id)" mat-button mat-raised-button color="warn">{{ 'Partner.Cancelorder' | translate }}</button>
          </div>
        </mat-card-content>
      </mat-card>
      <mat-card *ngIf="!deliveries?.length" [class.mat-elevation-z1]="true">{{ 'Partner.Nodeliveries' | translate }} </mat-card>
    </div>
  </div>
</div>