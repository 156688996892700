import { Input, ViewContainerRef, TemplateRef, OnInit, Directive, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { TokenStorageService } from '../services/token-storage.service'

@Directive({
  selector: '[ifRoles]'
})
export class IfRolesDirective implements OnInit, OnDestroy {
  private subscription: Subscription[] = [];
  // the role the user must have
  @Input() public ifRoles: Array<string>;

  /**
   * @param {ViewContainerRef} viewContainerRef -- the location where we need to render the templateRef
   * @param {TemplateRef<any>} templateRef -- the templateRef to be potentially rendered
   */
  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private tokenStorage: TokenStorageService
  ) {}

  public ngOnInit(): void {
    this.subscription.push(
      this.tokenStorage.getUserRoles().subscribe(res => {
        if (!res || !res.roles) {
          this.viewContainerRef.clear();
          return ;
        }
        const idx = res.roles.findIndex((element) => this.ifRoles.indexOf(element) !== -1);
        if (idx < 0) {
          this.viewContainerRef.clear();
        } else {
          this.viewContainerRef.createEmbeddedView(this.templateRef);
        }
      })
    );
  }

  public ngOnDestroy(): void {
    this.subscription.forEach((subscription: Subscription) => subscription.unsubscribe());
  }
  
}

