<div class="farmer-div">
  <mat-icon class="back-arrow" (click)="selectPrevStep()">arrow_back</mat-icon>
  <mat-horizontal-stepper #stepper>
    <mat-step>
      <mat-card (click)="selectStep(1)">
        <mat-card-title>{{ 'Farmer.vegetables' | translate }}</mat-card-title>
      </mat-card>
      <mat-card (click)="selectStep(2)">
        <mat-card-title>{{ 'Farmer.fruits' | translate }}</mat-card-title>
      </mat-card>
      <mat-card (click)="selectStep(3)">
        <mat-card-title>{{ 'Farmer.greens' | translate }}</mat-card-title>
      </mat-card>
      <mat-card (click)="selectStep(5, true)">
        <mat-card-title>{{ 'Farmer.individual' | translate }}</mat-card-title>
      </mat-card>
      <mat-card (click)="selectStep(7)">
        <mat-card-title>{{ 'Farmer.history' | translate }}</mat-card-title>
      </mat-card>
    </mat-step>
    <mat-step>
      <!-- legume -->
      <div class="product-list">
        <ng-container *ngFor="let product of products">
          <mat-card *ngIf="product.type == 'vegetable'" (click)="onProductSelect(product.name)" class="product-card" [ngClass]="{'card-selected mat-elevation-z4': selectedProduct == product.name}">
            <span [matTooltip]="selectedProduct == product.name" class="selection">
              <mat-icon>check_circle</mat-icon>
            </span>
            <div class="product-image-div">
              <img mat-card-image class="product-image" src="../../../assets/img/products/{{product.img}}">
            </div>
            <div class="mat-text">
              <p>{{ product.name | translate }}</p>
            </div>
          </mat-card>
        </ng-container>
      </div>
    </mat-step>
    <mat-step>
      <!-- fructe -->
      <div class="product-list">
        <ng-container *ngFor="let product of products">
          <mat-card *ngIf="product.type == 'fruit'" (click)="onProductSelect(product.name)" class="product-card" [ngClass]="{'card-selected mat-elevation-z4': selectedProduct == product.name}">
            <span [matTooltip]="selectedProduct == product.name" class="selection">
              <mat-icon>check_circle</mat-icon>
            </span>
            <div class="product-image-div">
              <img mat-card-image class="product-image" src="../../../assets/img/products/{{product.img}}">
            </div>
            <div class="mat-text">
              <p>{{ product.name | translate }}</p>
            </div>
          </mat-card>
        </ng-container>
      </div>
    </mat-step>
    <mat-step>
      <!-- verdeturi -->
      <div class="product-list">
        <ng-container *ngFor="let product of products">
          <mat-card *ngIf="product.type == 'leafy'" (click)="onProductSelect(product.name)" class="product-card" [ngClass]="{'card-selected mat-elevation-z4': selectedProduct == product.name}">
            <span [matTooltip]="selectedProduct == product.name" class="selection">
              <mat-icon>check_circle</mat-icon>
            </span>
            <div class="product-image-div">
              <img mat-card-image class="product-image" src="../../../assets/img/products/{{product.img}}">
            </div>
            <div class="mat-text">
              <p>{{ product.name | translate }}</p>
            </div>
          </mat-card>
        </ng-container>
      </div>
    </mat-step>
    <mat-step>
      <!-- own -->
    </mat-step>
    <mat-step>
      <!-- details -->
      <form [formGroup]="productOffer" class="offer-details">
        <mat-form-field *ngIf="selectedProduct == 'new'" class="width-100" appearance="fill">
          <mat-label>{{ 'Farmer.Name' | translate }}</mat-label>
          <input matInput formControlName="product" type="text">
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>{{ 'Farmer.Quantity' | translate }}</mat-label>
          <input matInput formControlName="quantity" type="text">
        </mat-form-field >
        <mat-button-toggle-group value="kg" aria-label="qtype" class="qtype" formControlName="quantityType">
          <mat-button-toggle value="pcs">{{ 'pcs' | translate }}</mat-button-toggle>
          <mat-button-toggle value="kg">{{ 'kgs' | translate }}</mat-button-toggle>
        </mat-button-toggle-group>
        <mat-form-field class="width-100" appearance="fill">
          <mat-label>{{ 'Farmer.Price' | translate }}</mat-label>
          <input matInput formControlName="price" type="number">
        </mat-form-field>
        <mat-form-field class="width-100" appearance="fill">
          <mat-label>{{ 'Farmer.Notes' | translate }}</mat-label>
          <textarea matInput formControlName="note"></textarea>
        </mat-form-field>
        <div fxLayout="row" fxLayoutAlign="end center">
          <button mat-button (click)="addProduct()" mat-raised-button color="primary">{{ 'Farmer.Add' | translate }}</button>
        </div>
      </form>
    </mat-step>
    <mat-step>
      <!-- checkout -->
      <div class="checkout-list">
        <mat-list>
          <ng-container *ngFor="let product of checkoutBox; let i = index">
            <mat-list-item>
              <div mat-line>{{ product.product | translate }}
                <span class="align-right">{{ product.quantity }} {{ product.quantityType | translate }} | {{ product.price }} {{ 'lei' | translate }}
                  <button class="icon-button" mat-icon-button (click)="removeProduct(i)"><mat-icon>close</mat-icon></button>
                </span>
              </div>
              <div mat-line *ngIf="product.note">{{ product.note }}</div>
            </mat-list-item>
            <mat-divider></mat-divider>
          </ng-container>
        </mat-list>
        <div class="buttons-align">
          <button mat-button class="action-button" (click)="addAnotherItem()" mat-raised-button>{{ 'Farmer.Add' | translate }}</button>
          <button mat-button class="action-button" (click)="submit()" mat-raised-button color="primary">{{ 'Farmer.confirm' | translate }}</button>
        </div>
      </div>
    </mat-step>
    <mat-step>
      <!-- awaiting -->
      <div class="available-offers">
        <mat-card class="offer" *ngFor="let offer of availableOffers" [class.green]="offer.requestedAmount && !offer.archived" [class.blured]="offer.archived">
          <mat-chip-list *ngIf="offer.requestedAmount" aria-label="Fish selection" fxLayoutAlign="end center">
            <!-- <mat-chip [innerHTML]="'Farmer.Pleasebringmessage' | translate:{'amount': offer.requestedAmount, 'type': offer.quantityType | translate}"></mat-chip>  -->
            <!-- {{ 'Farmer.Pleasebringmessage' | translate:{'amount': offer.requestedAmount, 'type': offer.quantityType} }} -->
          </mat-chip-list>
          <div class="full-width">
            <div class="half-width">
              <img [src]="offer.img != null ? ('../../../assets/img/products/' + offer.img) : ('../../../assets/img/products/unknown.jpg')">
            </div>
            <div class="half-width">
              <mat-list role="list">
                <mat-list-item role="listitem">{{ 'Farmer.Product' | translate }}:   {{ offer.productName | translate }}</mat-list-item>
                <mat-list-item role="listitem">{{ 'Farmer.Quantity' | translate }}:&nbsp;<span *ngIf="editingOfferId != offer._id">{{offer.quantity}}</span>&nbsp;<span *ngIf="editingOfferId != offer._id">{{offer.quantityType | translate}}</span>
                  <input matInput *ngIf="editingOfferId == offer._id" [(ngModel)]="offerDataInlineEdit.quantity" type="text" placeholder="Quantity">
                  <mat-button-toggle-group *ngIf="editingOfferId == offer._id" [(ngModel)]="offerDataInlineEdit.quantityType" [value]="offer.quantityType" aria-label="qtype" class="qtype">
                    <mat-button-toggle value="pcs">{{ 'pcs' | translate }}</mat-button-toggle>
                    <mat-button-toggle value="kg">{{ 'kg' | translate }}</mat-button-toggle>
                  </mat-button-toggle-group>
                </mat-list-item>
                <mat-list-item role="listitem">{{ 'Farmer.Price' | translate }}:	&nbsp;<span *ngIf="editingOfferId != offer._id">{{offer.price}}</span>
                  <input matInput *ngIf="editingOfferId == offer._id" [(ngModel)]="offerDataInlineEdit.price" type="text" placeholder="Price">
                </mat-list-item>
                <mat-list-item role="listitem">{{ 'Farmer.dateadded' | translate }}: {{ stringAsDate(offer.createdAt) | date:'dd/MM/yy HH:mm' }}</mat-list-item>
                <mat-list-item role="listitem">{{ 'Farmer.Notes' | translate}}:	&nbsp;<span *ngIf="editingOfferId != offer._id">{{offer.note}}</span>
                  <input matInput *ngIf="editingOfferId == offer._id" [(ngModel)]="offerDataInlineEdit.note" type="text" placeholder="Notes">
                </mat-list-item>
              </mat-list>
            </div>
          </div>
          <div class="align-right">
            <button mat-stroked-button (click)="duplicateOffer(offer._id)">{{ 'Farmer.repeat' | translate }}</button>
            <button mat-stroked-button *ngIf="editingOfferId != offer._id" (click)="inlineEditOffer(offer._id)">{{ 'Farmer.edit' | translate }}</button>
            <button mat-stroked-button *ngIf="editingOfferId == offer._id" color="primary" (click)="saveOfferEdits(offer._id)">{{ 'Farmer.save' | translate }}</button>
            <button mat-flat-button color="warn" (click)="deleteOffer(offer._id)">{{ 'Farmer.Remove' | translate }}</button>
          </div>
        </mat-card>
        <div *ngIf="availableOffers == [] || !availableOffers.length">
          <h2>{{ 'Farmer.nooffers' | translate }}</h2>
        </div>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</div>