import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  title = 'katalyst';
  logotext = ['local products', 'high quality', 'fresh products', 'shipping'];
  selectedMenu: string;

  constructor() { }

  ngOnInit(): void {
    this.selectedMenu = "EN";
  }

  languageSelect(menuItem: string){
    this.selectedMenu = menuItem;
  }

}
