import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent implements OnInit {

  selectedMenu: string;
  user;
  
  constructor(public tokenStorage: TokenStorageService, private router: Router, private translate: TranslateService) {
  }

  ngOnInit(): void {
    this.selectedMenu = (this.tokenStorage.getLang() ? this.tokenStorage.getLang() : 'ro');
    this.translate.use(this.selectedMenu);
    this.user = this.tokenStorage.getUser();
    this.tokenStorage.userAuthEvent.subscribe((data) => {
      if (data == 'logout') {
        this.user = null;
      } else if (data == 'login') {
        this.user = this.tokenStorage.getUser();
      }
    });
  }

  languageSelect(menuItem: string) {
    this.selectedMenu = menuItem;
    this.translate.use(menuItem);
    this.tokenStorage.saveLang(menuItem);
  }
  logout() {
    this.tokenStorage.logout();
    this.router.navigate(["home"]);
  }

}