import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { query } from '@angular/animations';

const MAP_API = 'https://map.md/api/companies/webmap/get_street';
const AUTH_TOKEN = '0f7c9286-1a79-4de6-ae55-91e669b7a0b9';

// const httpOptions = {
//   headers: new HttpHeaders().set(
//     'Authorization', 'Basic 636822c8-e78b-4e10-bb57-d91512a18631'
//     // 'Content-Type': 'application/json'
//   )
// };

@Injectable({
  providedIn: 'root'
})
export class MapapiService {

  constructor(private http: HttpClient) { }

  getCoordinates(client): Observable<any> { 
    let headers = new HttpHeaders().set(
        'Authorization', 'Basic 0f7c9286-1a79-4de6-ae55-91e669b7a0b9'
    );
    headers.set('Content-Type', 'application/json');
    return this.http.get(MAP_API+'?location='+client.city+'&number='+client.houseNumber+'&q='+client.address, {headers: headers});
  }
}