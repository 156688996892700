<div class="delivery" fxLayout="row" fxLayoutAlign="space-around start">
  <div class="orders-list" fxLayout="column" fxLayoutAlign="space-around center" fxFlex.gt-sm="70%" fxFlex="100%">
    <mat-accordion [multi]="false" *ngFor="let delivery of deliveries">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Client: {{ delivery.client.name }} |  Date: {{ stringAsDate(delivery.createdAt) | date:'dd/MM/yy HH:mm' }}
          </mat-panel-title>
          <mat-panel-description>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div fxLayout.gt-sm="row" fxLayout="column" fxLayoutAlign.gt-sm="space-around start">
          <!-- <img fxFlex="45%" src="../../../assets/img/mapmodel.png"> -->
          <iframe allow="geolocation" fxFlex="90" fxFlex.gt-sm="60" height="300" frameborder="0" #mapIframes [src]="delivery.client.coordinates.mapUrl"></iframe><!-- [srcdoc]="delivery.client.coordinates.mapUrl"   src="x"> </iframe> -->
          <div fxLayout="column" fxLayoutAlign="space-between center">
            <p>{{ 'Delivery.Address' | translate }}: {{ delivery.client.city }}, {{ delivery.client.address }}, {{ delivery.client.houseNumber }}</p>
            <p>{{ 'Delivery.Order' | translate }}: {{ delivery.size }} {{ delivery.client.weight }}</p>
            <button (click)="setDelivered(delivery._id)" mat-button mat-raised-button color="primary">{{ 'Delivery.Delivered' | translate }}</button>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <mat-card *ngIf="!deliveries?.length" [class.mat-elevation-z1]="true">{{ 'Delivery.Nodelivieries' | translate }} </mat-card>
  </div>
</div>