import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeliveryService } from 'src/app/services/delivery.service';
import { MapapiService } from 'src/app/services/mapapi.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-delivery',
  templateUrl: './delivery.component.html',
  styleUrls: ['./delivery.component.scss']
})
export class DeliveryComponent implements OnInit {
  deliveries = [];
  @ViewChildren('mapIframes') iframes: QueryList<ElementRef>;

  constructor(private deliveryService: DeliveryService,
              private _snackBar: MatSnackBar,
              private mapAPIService: MapapiService,
              private domSanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.deliveryService.getDeliveries({deliveredTime: null}).subscribe(
      data => {
        if (data.message != "Not found.") {
          this.deliveries = data;
          this.deliveries.forEach((delivery, index) => {
            this.deliveries[index].client.coordinates = {};
            this.mapAPIService.getCoordinates(delivery.client).subscribe(data => {
              this.deliveries[index].client['coordinates'] = data;
              this.deliveries[index].client['coordinates'].mapUrl = 
              this.domSanitizer.bypassSecurityTrustResourceUrl(`https://map.md/ro/?embed=1#16/${this.deliveries[index].client['coordinates'].point.lat}/${this.deliveries[index].client['coordinates'].point.lon}/`);
            }, err => {
              this.deliveries[index].client.coordinates.mapUrl = 
              this.domSanitizer.bypassSecurityTrustResourceUrl('https://map.md/ro/?embed=1#11/47.02301/28.83368/');
            });
          });
        }
      }
    );
  }

  setDelivered(deliveryId) {
    this.deliveryService.setDelivered(deliveryId).subscribe((data) => {
      let snackBarRef = this._snackBar.open("Delivered!", "Undo", {
        duration: 4500,
      });
      let obj = this.deliveries.findIndex(obj => obj._id == deliveryId);
      if (obj > -1) {
        this.deliveries.splice(obj, 1);
      }
      snackBarRef.onAction().subscribe(() => {
        this.deliveryService.unsetDelivered(deliveryId).subscribe((data) => {
          let snackBarRef = this._snackBar.open("Undelivered!", "", {
            duration: 3500,
          });
          this.deliveryService.getDeliveries().subscribe(data => {
            if (data.message != "Not found.") {
              this.deliveries = data;
            }
          });
        });
      });
    });
  }
  
  stringAsDate(dateStr: string) {
    return new Date(dateStr);
  }

}
