<footer class="footer" fxLayoutAlign="space-around center">
  <button mat-button (click)="goup()"><mat-icon>keyboard_arrow_up</mat-icon></button>
  <div fxLayout="row wrap" fxLayoutAlign="space-between center" fxFlex="66%" fxFlex.xs="100%">
    <mat-card class="mat-elevation-z" fxFlex="33%" fxFlex.sm="50%" fxFlex.xs="100%">
      <mat-card-header>
        <mat-card-title>About us</mat-card-title>
        <mat-card-subtitle>lorem ipsum dolor canin much more text repeat hand written text again and again should
          be a little lower positioned</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <mat-icon>message</mat-icon>
        <mat-icon>message</mat-icon>
        <mat-icon>message</mat-icon>
      </mat-card-content>
    </mat-card>
    <mat-card class="mat-elevation-z" fxFlex="33%" fxFlex.sm="50%" fxFlex.xs="100%">
      <mat-card-header>
        <mat-card-title>Contact</mat-card-title>
        <mat-card-subtitle>lorem ipsum dolor canin much more text repeat hand written text again and again should
          be a little lower positioned</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <mat-icon>message</mat-icon>
        <mat-icon>message</mat-icon>
        <mat-icon>message</mat-icon>
      </mat-card-content>
    </mat-card>
  </div>
</footer>