import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroupDirective, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DeliveryService } from 'src/app/services/delivery.service';
import { TokenStorageService } from '../../services/token-storage.service';

@Component({
  selector: 'app-partner',
  templateUrl: './partner.component.html',
  styleUrls: ['./partner.component.scss']
})
export class PartnerComponent implements OnInit {

  deliveries = [];
  clients = [];
  orderForm;
  @ViewChild(FormGroupDirective) formdirective;
  filteredOptions: Observable<string[]>;
  selectedClient = "";

  constructor(private deliveryService: DeliveryService,
              private tokenStorage: TokenStorageService,
              private formBuilder: FormBuilder,
              private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.deliveryService.getDeliveries({deliveredTime: null, user: JSON.stringify(this.tokenStorage.getUser())}).subscribe(
      data => {
        console.log(data);
        if (data.message != "Not found.") {
          this.deliveries = data;
        }
      }
    );
    this.deliveryService.getClients().subscribe(data => {
      this.clients = data;
    });
    this.orderForm = this.formBuilder.group({
      'name': ['', Validators.compose([Validators.required])],
      'phone': ['', Validators.compose([Validators.required])],
      'city': ['', Validators.compose([Validators.required])],
      'street': ['', Validators.compose([Validators.required])],
      'number': ['', Validators.compose([Validators.required])],
      'bagWeight': ['', Validators.compose([Validators.required])],
      'bagSize': ['', Validators.compose([Validators.required])]
    });
    this.orderForm.controls['bagSize'].setValue('medium');
    this.filteredOptions = this.orderForm.get('name').valueChanges
      .pipe(
        startWith(''),
        map(val => this.filterNames(val))
      );
  
  }

  addOrder(deliveryData: any) {
    deliveryData['createdBy'] = this.tokenStorage.getUser();

    if (this.selectedClient == "") {
      this.deliveryService.createClient(deliveryData).subscribe((r) => {
        deliveryData['clientId'] = r._id;
        this.clients.push(r);
        this.deliveryService.createDelivery(deliveryData).subscribe((data) => {
          this.deliveryService.getDeliveries({deliveredTime: null, user: JSON.stringify(this.tokenStorage.getUser())}).subscribe(
            data => {
              if (data.message != "Not found.") {
                this.deliveries = data;
              }
            }
          );
          let snackBarRef = this._snackBar.open("Order added!", "Undo", {
            duration: 4500,
          });
          snackBarRef.onAction().subscribe(() => {
            this.deleteDelivery(data._id);
          });
        },
        err => {
          console.log(err.error.message);
        });
      });
    } else {
      this.deliveryService.editClient(this.selectedClient, deliveryData).subscribe((r) => {
        deliveryData['clientId'] = r._id;
        let index = this.clients.findIndex(client => client._id === r._id);
        this.clients[index] = r;
        this.deliveryService.createDelivery(deliveryData).subscribe((data) => {
          this.deliveryService.getDeliveries({deliveredTime: null, user: JSON.stringify(this.tokenStorage.getUser())}).subscribe(
            data => {
              if (data.message != "Not found.") {
                this.deliveries = data;
              }
            }
          );
          let snackBarRef = this._snackBar.open("Order added!", "Undo", {
            duration: 4500,
          });
          snackBarRef.onAction().subscribe(() => {
            this.deleteDelivery(data._id);
          });
        },
        err => {
          console.log(err.error.message);
        });
      });
    }

    this.formdirective.resetForm();
    this.orderForm.reset();
  }

  deleteDelivery(deliveryId) {
    this.deliveryService.deleteDelivery(deliveryId).subscribe(
      data => {
        let obj = this.deliveries.findIndex(obj => obj._id == deliveryId);
        if (obj > -1) {
          this.deliveries.splice(obj, 1);
        }
        let snackBarRef = this._snackBar.open("Order canceled!", "", {
          duration: 3500,
        });
      },
      err => {
        console.log(err.error.message);
      }
    );
  }

  autoSelectionAction(event) {
    let id = event.option.value;
    let index = this.clients.findIndex(client => client._id === id);
    this.orderForm.get('name').setValue(this.clients[index].name);
    this.orderForm.get('phone').setValue(this.clients[index].phone);
    this.orderForm.get('city').setValue(this.clients[index].city);
    this.orderForm.get('street').setValue(this.clients[index].address);
    this.orderForm.get('number').setValue(this.clients[index].houseNumber);

    this.selectedClient = id;
  }

  stringAsDate(dateStr: string) {
    return new Date(dateStr);
  }

  filterNames(val) {
    this.selectedClient = "";
    let arr: any[];
    if (val) {
      arr = this.clients.filter(s => new RegExp(`^${val}`, 'gi').test(s.name));
    } else {
      arr = this.clients;
    }
    return arr;
  }
}
