import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

const CRUD_API = 'https://app.farms.md/api/offers/';
const CRUD_API_archived = 'https://app.farms.md/api/offers/archived/';
const CRUD_API_not_archived = 'https://app.farms.md/api/offersnotarchived/';
const USERS_API = 'https://app.farms.md/api/users/';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class OfferService {
  constructor(private http: HttpClient) { }

  getOffers(user, cond = []): Observable<any> {
    let params = new HttpParams().set("username", user.username);
    Object.keys(cond).forEach(function (key) {
      params = params.append(key, cond[key]);
    });
    return this.http.get(CRUD_API, {params: params});
  }

  getOffersArchived(user, currentPage = 0, limit = 25): Observable<any> {
    let params = new HttpParams().set("username", user.username)
                                 .set("page", currentPage.toString())
                                 .set("limit", limit.toString());
    return this.http.get(CRUD_API_archived, {params: params});
  }

  getOffersNotArchived(user, currentPage = 0, limit = 25): Observable<any> {
    let params = new HttpParams().set("username", user.username)
                                 .set("page", currentPage.toString())
                                 .set("limit", limit.toString());
    return this.http.get(CRUD_API_not_archived, {params: params});
  }

  getFarmers(): Observable<any> {
    return this.http.get(USERS_API + "farmer/");
  }

  getUsers(currentPage = 0, limit = 25): Observable<any> {
    let params = new HttpParams().set("page", currentPage.toString())
                                 .set("limit", limit.toString());
    return this.http.get(USERS_API, {params: params});
  }

  updateUser(id, data): Observable<any> {    
    data.userId = id;
    return this.http.put(USERS_API, data, httpOptions);
  }

  createOffer(offer): Observable<any> {
    return this.http.post(CRUD_API, {
      user: offer.user,
      createdBy: offer.createdBy,
      productName: offer.product,
      quantity: offer.quantity,
      quantityType: offer.quantityType,
      price: offer.price,
      note: offer.note,
      transport: false
    }, httpOptions);
  }

  deleteOffer(offerId) {
    return this.http.delete(CRUD_API, {
      params: {
        offerId: offerId
      }
    });
  }

  archiveOffer(id): Observable<any> {
    return this.http.put(CRUD_API, {
      offerId: id,
      archive: true
    }, httpOptions);
  }

  orderOffer(id, amount): Observable<any> {
    return this.http.put(CRUD_API, {
      offerId: id,
      amount: amount
    }, httpOptions);
  }

  updateOffer(id, data): Observable<any> {
    data.offerId = id;
    return this.http.put(CRUD_API, data, httpOptions);
  }
}