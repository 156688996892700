<div fxLayout="column" fxLayoutAlign="center">
  <div class="home-images" fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="space-around center">
    <img src="./../../assets/img/ecovillage.png">
    <img src="./../../assets/img/katalyst_short.png">
    <img src="./../../assets/img/greendelivery.png">
  </div>
  <div class="footer" fxLayout="row wrap" fxLayoutAlign="space-around center">
    <p fxFlex="100%" style="text-align: center;">This application was developed by <b>Pro Katalyst ltd</b> with support of <b>EU4Energy</b> and <b>Green for Growth Fund Technical Assistance Facility</b></p>
    <div fxLayout="column" fxLayout="row" fxLayoutAlign="center">
      <img src="../../assets/img/EU4Energylogo.png">
      <img src="../../assets/img/GGFlogo.jpg">
    </div>
  </div>
</div>
<!-- 
<section class="icons-section" fxLayoutAlign="space-around center">
  <div class="content" fxFlex="70%" fxLayout="row wrap" fxLayoutAlign="space-between start">
    <div class="logo-card-div" fxFlex="25%" fxFlex.xs="100%" fxFlex.sm="40%" *ngFor="let num of [1,2,3,4]">
      <mat-card class="logo-card mat-elevation-z">
        <img mat-card-image src="{{'./../assets/img/logo' + num + '.png'}}">
        <mat-card-content>
          <mat-card-title>{{logotext[num-1]}}</mat-card-title>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</section>
<section class="mat-typography abouts">
  <div class="aboutc container" fxLayout="column" fxLayoutAlign="space-around center">
    <div class="about-section about-heading" fxLayout="column" fxLayoutAlign="space-around center">
      <div>
        <h2 [ngStyle.lt-md]="{'margin-bottom': '15px'}">About us</h2>
      </div>
      <div>
        <h3 [ngStyle.xs]="{'font-size': '18px'}" [ngStyle.sm]="{'font-size': '22px'}">Lorem ipsum dolor sit amet,
          consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</h3>
      </div>
    </div>
    <div class="about-content" fxLayout="row wrap" fxLayoutAlign="space-around center" fxFlex="26%" fxFlex.xs="100%" fxFlex.sm="40%">
      <mat-card class="about-card" fxLayout="column" fxLayoutAlign="space-between center">
        <mat-card-header>
          <mat-card-title>Shiba Inu</mat-card-title>
          <mat-card-subtitle>lorem ipsum dolor canin much more text repeat hand written text again and again should
            be a little lower positioned</mat-card-subtitle>
        </mat-card-header>
        <div class="about-img about-img-1"></div>
        <img mat-card-image src="./../assets/img/about1.jpg">
      </mat-card>
      <mat-card class="about-card" fxLayout="column" fxLayoutAlign="space-between center">
        <mat-card-header>
          <mat-card-title>Shiba Inu</mat-card-title>
          <mat-card-subtitle>lorem ipsum dolor canin much more text repeat hand written text again and again should
            be a little lower positioned</mat-card-subtitle>
        </mat-card-header>
        <div class="about-img about-img-2"></div>
        <img mat-card-image src="./../assets/img/about2.jpg">
      </mat-card>
      <mat-card class="about-card" fxLayout="column" fxLayoutAlign="space-between center">
        <mat-card-header>
          <mat-card-title>Shiba Inu</mat-card-title>
          <mat-card-subtitle>lorem ipsum dolor canin much more text repeat hand written text again and again should
            be a little lower positioned</mat-card-subtitle>
        </mat-card-header>
        <div class="about-img about-img-3"></div>
        <img mat-card-image src="./../assets/img/about3.png">
      </mat-card>
      <mat-card class="about-card" fxLayout="column" fxLayoutAlign="space-between center">
        <mat-card-header>
          <mat-card-title>Shiba Inu</mat-card-title>
          <mat-card-subtitle>lorem ipsum dolor canin much more text repeat hand written text again and again should
            be a little lower positioned</mat-card-subtitle>
        </mat-card-header>
        <div class="about-img about-img-4"></div>
        <img mat-card-image src="./../assets/img/about4.jpg">
      </mat-card>
    </div>
  </div>
</section> -->