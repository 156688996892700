
    <div fxFlexFill fxLayoutAlign="space-around center">
      <form [formGroup]="form" (ngSubmit)="onSubmit()" class="register-container">
        <mat-form-field appearance="fill">
          <mat-label>{{ 'Register.Name' | translate }}</mat-label>
          <input formControlName="name" matInput>
        </mat-form-field>
        <br>
        <mat-form-field appearance="fill">
          <mat-label>{{ 'Register.Firstname' | translate }}</mat-label>
          <input formControlName="firstname" matInput>
        </mat-form-field>
        <br>
        <mat-form-field appearance="fill">
          <mat-label>{{ 'Register.Username' | translate }}</mat-label>
          <input formControlName="username" matInput>
        </mat-form-field>
        <br>
        <mat-form-field appearance="fill">
          <mat-label>{{ 'Register.Password' | translate }}</mat-label>
          <input formControlName="password" type="password" matInput>
          <mat-error *ngIf="form.get('password').errors?.required">{{ 'Register.Enterpassword' | translate }}</mat-error>
        </mat-form-field>
        <br>
        <mat-form-field appearance="fill">
          <mat-label>{{ 'Register.Phone' | translate }}</mat-label>
          <input formControlName="phone" type="text" matInput>
        </mat-form-field>
        <br>
        <mat-form-field appearance="fill">
          <mat-label>{{ 'Register.Role' | translate }}</mat-label>
          <mat-select formControlName="role">
            <mat-option value="farmer">{{ 'Register.Farmer' | translate }}</mat-option>
            <mat-option value="coordinator">{{ 'Register.coordinator' | translate }}</mat-option>
            <!-- <mat-option value="partner">{{ 'Register.Partner' | translate }}</mat-option> -->
            <mat-option value="manager">{{ 'Register.Manager' | translate }}</mat-option>
            <!-- <mat-option value="delivery">{{ 'Register.Deliveryman' | translate }}</mat-option> -->
          </mat-select>
        </mat-form-field>
        <br>
        <button type="submit" [disabled]="form.invalid || submitted" mat-raised-button color="primary">{{ 'Register.Submit' | translate }}</button>
      </form>
    </div>