<div fxFlexFill fxLayoutAlign="space-around center">
  <form [formGroup]="form" (ngSubmit)="onSubmit()" class="register-container">
    <mat-form-field appearance="fill">
      <mat-label>{{ 'Login.Username' | translate }}</mat-label>
      <input formControlName="username" matInput>
    </mat-form-field>
    <br>
    <mat-form-field appearance="fill">
      <mat-label>{{ 'Login.Password' | translate }}</mat-label>
      <input formControlName="password" type="password" matInput>
      <mat-error *ngIf="form.get('password').errors?.required">{{ 'Login.Enterpassword' | translate }}</mat-error>
    </mat-form-field>
    <br>
    <button type="submit" [disabled]="form.invalid || submitted" mat-raised-button color="primary">{{ 'Login.Submit' | translate }}</button>
  </form>
</div>