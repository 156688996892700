import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material-module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { LayoutModule } from '@angular/cdk/layout';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FarmerComponent } from './dashboard/farmer/farmer.component';
import { PartnerComponent } from './dashboard/partner/partner.component';
import { DeliveryComponent } from './dashboard/delivery/delivery.component';
import { DialogNewItem, InventoryComponent } from './dashboard/inventory/inventory.component';
import { MatPaginatorGotoComponent } from './dashboard/inventory/mat-paginator-goto/mat-paginator-goto.component';
import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { AuthInterceptor } from './helpers/auth.interceptor';
import { IfRolesDirective } from './helpers/if-roles.directive';
import { DashboardComponent } from './dashboard/dashboard/dashboard.component';
import { CoordinatorComponent } from './dashboard/coordinator/coordinator.component'
import { InlineEditComponent } from './inline-edit/inline-edit.component';
import { SatPopoverModule } from '@ncstate/sat-popover';
import { AgmCoreModule } from '@agm/core';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { registerLocaleData } from '@angular/common';
import localeRo from '@angular/common/locales/ro';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatFileUploadModule } from 'angular-material-fileupload';
registerLocaleData(localeRo);

// export function translateHttpLoaderFactory(http: HttpClient) {
//   return new TranslateHttpLoader(http, './assets/locale/', '.json');
// }
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/locale/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    FarmerComponent,
    PartnerComponent,
    DeliveryComponent,
    InventoryComponent,
    MatPaginatorGotoComponent,
    FooterComponent,
    NavbarComponent,
    HomeComponent,
    LoginComponent,
    RegisterComponent,
    IfRolesDirective,
    DashboardComponent,
    CoordinatorComponent,
    InlineEditComponent,
    DialogNewItem
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    MaterialModule,
    LayoutModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SatPopoverModule,
    MaterialFileInputModule,
    MatPaginatorModule,
    MatSelectModule,
    MatToolbarModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatRippleModule,
    MatFileUploadModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyB56Yfh4sJ6xaPDuAnDyG39X9eDHxkczew',
      libraries: ['places']
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        // useFactory: translateHttpLoaderFactory,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    MatGoogleMapsAutocompleteModule
  ],
  providers: [
    AuthInterceptor,
    { provide: LOCALE_ID, useValue: 'ro-RO' },
  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
})

export class AppModule {
}