<mat-tab-group>
  <mat-tab label="{{ 'Inventory.Offers' | translate }}">
      <div class="buton-control">
        <button mat-button (click)="exportExcel()" mat-raised-button color="primary">{{'Inventory.Export' | translate }}</button>
      </div>
    <div class="offers" fxLayoutAlign="center">
      <div class="available-offers" fxFlex.gt-sm="75%" fxFlex="100%" fxLayoutAlign="space-around start">
        <div *ngIf="counter != 0">
          <div *ngFor="let offer of availableOffers">
            <mat-card class="offer" *ngIf="offer.archived == false || !offer.archived">
              <div fxLayout.gt-xs="row" fxLayout="column" fxLayoutAlign="center center">
                <div class="offer-li" fxLayoutAlign="space-around center" fxFlex.gt-xs="5%" fxFlex="100%">
                  <mat-checkbox class="example-margin" [(ngModel)]="offer.export_check"></mat-checkbox>
                </div>
                <!-- <input matInput type="checkbox" [value]="offer._id"> -->
                <div class="offer-li" fxLayoutAlign="space-around center" fxFlex.gt-xs="20%" fxFlex="100%">
                  <img fxFlex.xs="50%" matCardImage
                    [src]="offer.img != null ? ('../../../assets/img/products/' + offer.img) : ('../../../assets/img/products/unknown.jpg')">
                </div>
                <div fxLayout="row wrap" fxLayoutAlign.gt-xs="start center" fxLayoutAlign="space-around center"
                  fxFlex.gt-xs="75%" fxFlex="100%">
                  <div class="offer-li" fxFlex.xs="50%">
                    <span>{{ 'Inventory.Product' | translate }}: {{offer.productName | translate }}</span>
                  </div>
                  <div class="offer-li" fxFlex.xs="50%">
                    <span>{{ 'Inventory.Farmer' | translate }}: {{offer.user.name}}</span>
                  </div>
                  <div class="offer-li" fxFlex.xs="50%">
                    <span>{{ 'Inventory.Quantity' | translate }}: {{offer.quantity}} {{offer.quantityType | translate
                      }}</span>
                  </div>
                  <div class="offer-li" fxFlex.xs="50%">
                    <span>{{ 'Inventory.Price' | translate }}: {{offer.price}} {{ 'lei' | translate }}</span>
                  </div>
                  <div class="offer-li" fxFlex.xs="50%">
                    <span>{{ 'Inventory.dateadded' | translate }}: {{ stringAsDate(offer.createdAt) | date:'dd/MM/yy
                      HH:mm' }}</span>
                  </div>
                  <div class="offer-li" fxFlex.xs="50%">
                    <span>{{ 'Inventory.note' | translate }}: {{offer.note}}</span>
                  </div>
                </div>
                <div class="offer-li" fxLayout="column" fxLayoutAlign="space-around end" fxFlex.xs="100%">
                  <div fxLayout="row">
                    <button mat-button (click)="archive(offer._id)" mat-raised-button color="warning">{{
                      'Inventory.Archive' | translate }}</button>
                  </div>
                  <div fxLayout="row" *ngIf="offer.requestedAmount == 0 || !offer.requestedAmount">
                    <mat-form-field class="small-input">
                      <mat-label>{{ 'Inventory.Amount' | translate }}</mat-label>
                      <input matInput type="number" min="0" [max]="offer.quantity" [value]="offer.quantity"
                        (input)="offer.amount = $event.target.value" #orderAmount>
                    </mat-form-field>
                    <button mat-button [disabled]="offer.requestedAmount > 0"
                      (click)="order(offer._id, offer.amount || offer.quantity); orderAmount.value=''" mat-raised-button
                      color="primary">{{offer.transport ? "Ordered" : "Order" | translate}} </button>
                  </div>
                </div>
              </div>
            </mat-card>
          </div>
          <mat-paginator-goto aria-label="Select page" [length]="offers.totalSize" [pageIndex]="0"
            [pageSize]="offers.pageSize" [pageSizeOptions]="[5, 10, 25, 100]" (page)="handlePage($event)">
          </mat-paginator-goto>
        </div>
        <div *ngIf="counter == 0">
          <h2>{{ 'Inventory.nooffers' | translate }}</h2>
        </div>
      </div>
    </div>
  </mat-tab>
  <mat-tab label="{{ 'Inventory.Bagsbuilder' | translate }}">
    <div class="bags-builder" fxLayout="row wrap" fxLayoutAlign="space-around start">
      <div class="bags-col" fxFlex.gt-xs="40%" fxFlex="90%">
        <form [formGroup]="orderForm" (ngSubmit)="addOrder(orderForm.value)">
          <mat-card class="order-form" fxLayout="row wrap" fxLayoutAlign="space-between">
            <mat-form-field appearance="outline" fxFlex="100%">
              <mat-label>
                <mat-icon>person</mat-icon> {{ 'Inventory.Clientname' | translate }}
              </mat-label>
              <input matInput formControlName="name" type="text" [matAutocomplete]="autoname">
              <mat-autocomplete #autoname="matAutocomplete" (optionSelected)='autoSelectionAction($event)'>
                <mat-option *ngFor="let client of filteredOptions | async" [value]="client._id">
                  {{ client.name }}
                </mat-option>
              </mat-autocomplete>
              <!-- <mat-error *ngIf="orderForm.get('name').hasError('required')">Name is <strong>required</strong></mat-error> -->
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex.gt-sm="48%" fxFlex="100%">
              <mat-label>
                <mat-icon>call</mat-icon> {{ 'Inventory.Phone' | translate }}
              </mat-label>
              <input matInput formControlName="phone" type="text">
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex="100%" fxFlex.gt-sm="72%">
              <mat-label>
                <mat-icon>location_on</mat-icon> {{ 'Inventory.Street' | translate }}
              </mat-label>
              <input matInput matGoogleMapsAutocomplete country="MD"
                (onAutocompleteSelected)="googleMapsSelected($event)"
                (onLocationSelected)="googleMapsSelectedLoc($event)" formControlName="street" #street type="text">
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex="100%" fxFlex.gt-sm="24%">
              <mat-label>Nr / Ap</mat-label>
              <input matInput formControlName="number" type="text">
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex.gt-sm="48%" fxFlex="100%">
              <mat-label>{{ 'Inventory.City' | translate }}</mat-label>
              <input matInput formControlName="city" type="text">
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex="48%">
              <mat-label>
                <mat-icon>shopping_bag</mat-icon> {{ 'Inventory.Bagweight' | translate }}
              </mat-label>
              <input matInput formControlName="bagWeight" type="text">
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex="48%">
              <mat-label>{{ 'Inventory.Bagsize' | translate }}</mat-label>
              <mat-select formControlName="bagSize" value="medium">
                <mat-option value="small">
                  {{ 'Inventory.Small' | translate }}
                </mat-option>
                <mat-option value="medium">
                  {{ 'Inventory.Medium' | translate }}
                </mat-option>
                <mat-option value="large">
                  {{ 'Inventory.Large' | translate }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div class="width-100" align="end">
              <button type="submit" mat-button mat-raised-button color="primary" [disabled]="orderForm.invalid">{{
                'Inventory.Addorder' | translate }}</button>
            </div>
          </mat-card>
        </form>
        <mat-card [class.mat-elevation-z1]="true" class="mt-1">
          <h4>Import excel</h4>
          <mat-form-field>
            <ngx-mat-file-input (change)="incomingfile($event)" accept=".xlsx" placeholder="Import excel">
            </ngx-mat-file-input>
            <mat-icon matSuffix>folder</mat-icon>
          </mat-form-field>
          <button type="button" mat-button mat-raised-button color="primary" [disabled]="!file" class="btn btn-info"
            (click)="Upload()">Upload</button>
        </mat-card>
        <mat-card class="order-card mt-1" fxLayout="row wrap" fxLayoutAlign="space-between"
          *ngFor="let item of uploadItems; let i = index">
          <mat-card-content fxLayout="column" fxLayoutAlign="space-between start">
            <div>Nume: {{ item.nume }} </div>
            <div>Telefon: {{ item.telefon }} </div>
            <div>Numar: {{ item.numar }} </div>
            <div>Strada: {{ item.strada }} </div>
            <div>Oras: {{ item.oras }}</div>
          </mat-card-content>
          <div fxLayout="row" fxLayoutAlign="end center" fxFlex="100%">
            <button type="button" (click)="addClient(i)" mat-button mat-raised-button color="primary">Adauga</button>
          </div>
        </mat-card>
      </div>
      <div class="bags-col" fxFlex.gt-xs="40%" fxFlex="90%">
        <mat-card class="order-card" fxLayout="row wrap" fxLayoutAlign="space-between"
          *ngFor="let delivery of deliveries">
          <mat-card-content fxLayout="row wrap" fxLayoutAlign="space-between">
            <mat-card [class.mat-elevation-z1]="true" fxFlex.gt-xs="49%">{{ 'Inventory.Clientname' | translate }}: {{
              delivery.client.name }} </mat-card>
            <mat-card [class.mat-elevation-z1]="true" fxFlex.gt-xs="49%">{{ 'Inventory.Phone' | translate }}: {{
              delivery.client.phone }} </mat-card>
            <mat-card [class.mat-elevation-z1]="true" fxFlex="100%">{{ 'Inventory.City' | translate }}: {{
              delivery.client.city }}, {{ 'Inventory.Address' | translate }}: {{ delivery.client.address }}, {{
              delivery.client.houseNumber}}</mat-card>
            <mat-card [class.mat-elevation-z1]="true">{{ 'Inventory.Bagweight' | translate }}: {{ delivery.weight}} | {{
              'Inventory.Bagsize' | translate }}: {{ delivery.size }}</mat-card>
            <mat-card [class.mat-elevation-z1]="true">{{ 'Inventory.Dateadded' | translate }}: {{
              stringAsDate(delivery.createdAt) | date:'dd/MM/yy HH:mm' }}</mat-card>
            <div fxLayout="row" fxLayoutAlign="end center" fxFlex="100%">
              <button type="button" (click)="deleteDelivery(delivery._id)" mat-button mat-raised-button color="warn">{{
                'Inventory.Cancelorder' | translate }}</button>
            </div>
          </mat-card-content>
        </mat-card>
        <mat-card *ngIf="!deliveries?.length" [class.mat-elevation-z1]="true">{{ 'Inventory.Nodeliveries' | translate }}
        </mat-card>
      </div>
    </div>
  </mat-tab>
  <mat-tab label="{{ 'Inventory.Ordered' | translate }}">
    <div class="offers" fxLayoutAlign="space-around start">
      <div class="available-offers" fxFlex.gt-sm="75%" fxFlex="100%" fxLayoutAlign="space-around start">
        <div *ngIf="awaitingOffers.length">
          <div *ngFor="let offer of awaitingOffers">
            <mat-card class="offer" *ngIf="offer.archived == false || !offer.archived">
              <div fxLayout.gt-xs="row" fxLayout="column" fxLayoutAlign="center center">
                <div class="offer-li" fxLayoutAlign="space-around center" fxFlex.gt-xs="25%" fxFlex="100%">
                  <img fxFlex.xs="50%" matCardImage
                    [src]="offer.img != null ? ('../../../assets/img/products/' + offer.img) : ('../../../assets/img/products/unknown.jpg')">
                </div>
                <div fxLayout="row wrap" fxLayoutAlign.gt-xs="start center" fxLayoutAlign="space-around center"
                  fxFlex.gt-xs="75%" fxFlex="100%">
                  <div class="offer-li" fxFlex.xs="50%">
                    <span>{{ 'Inventory.Product' | translate }}: {{offer.productName | translate }}</span>
                  </div>
                  <div class="offer-li" fxFlex.xs="50%">
                    <span>{{ 'Inventory.Farmer' | translate }}: {{offer.user.name}}</span>
                  </div>
                  <div class="offer-li" fxFlex.xs="50%">
                    <span>{{ 'Inventory.Regquantity' | translate }}: {{offer.requestedAmount}} {{ offer.quantityType |
                      translate }}</span>
                  </div>
                  <div class="offer-li" fxFlex.xs="50%">
                    <span>{{ 'Inventory.Price' | translate }}: {{offer.price}} {{ 'lei' | translate }}</span>
                  </div>
                  <div class="offer-li" fxFlex.xs="50%">
                    <span>{{ 'Inventory.dateadded' | translate }}: {{ stringAsDate(offer.createdAt) | date:'dd/MM/yy
                      HH:mm' }}</span>
                  </div>
                </div>
                <div class="offer-li" fxLayout="column" fxLayoutAlign="space-around end" fxFlex.xs="100%">
                  <div fxLayout="row">
                    <button mat-button (click)="order(offer._id, 0)" mat-raised-button color="warning">{{
                      'Inventory.Cancelorder' | translate }}</button>
                  </div>
                </div>
              </div>
            </mat-card>
          </div>
        </div>
        <div *ngIf="!awaitingOffers.length">
          <h2>{{ 'Inventory.Noorderssheduled' | translate }}</h2>
        </div>
      </div>
    </div>
  </mat-tab>
  <mat-tab label="{{ 'Inventory.SMS' | translate }}">
    <div class="sms-page" fxLayout="row wrap" fxLayoutAlign="space-around start">
      <div class="sms-form" fxFlex.gt-xs="40%" fxFlex="90%">
        <p class="mat-display-1" style="margin: 10px 0;">{{ 'Inventory.sms.balance' | translate }}: {{ smsBalance }}</p>
        <form [formGroup]="smsForm" (ngSubmit)="sendSMS(smsForm.value)">
          <mat-card class="order-form">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>{{ 'Inventory.sms.to' | translate }}</mat-label>
              <mat-select formControlName="to">
                <mat-option value="all">
                  {{ 'Inventory.sms.everyone' | translate }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>{{ 'Inventory.sms.message' | translate }}</mat-label>
              <textarea matInput formControlName="message"></textarea>
            </mat-form-field>
            <div class="width-100" align="end">
              <button type="submit" mat-button mat-raised-button color="primary"
                [disabled]="smsForm.invalid || sendingSMS">{{ 'Inventory.sms.send' | translate }}</button>
            </div>
          </mat-card>
        </form>
      </div>
      <div class="bags-col" fxFlex.gt-xs="40%" fxFlex="90%">
        <mat-card class="sms-card" fxLayout="row wrap" fxLayoutAlign="space-between" *ngFor="let sms of availableSmses">
          <mat-card-content fxLayout="row wrap" fxLayoutAlign="space-between">
            <mat-card class="mat-elevation-z0" fxFlex.gt-xs="49%">{{ 'Inventory.sms.message' | translate }}: {{
              sms.message }} </mat-card>
            <mat-card class="mat-elevation-z0">{{ 'Inventory.Dateadded' | translate }}: {{ stringAsDate(sms.createdAt) |
              date:'dd/MM/yy HH:mm' }}</mat-card>
          </mat-card-content>
        </mat-card>
        <mat-card *ngIf="!availableSmses.length" [class.mat-elevation-z1]="true">{{ 'Inventory.sms.nomessages' |
          translate }} </mat-card>
      </div>
    </div>
  </mat-tab>
  <mat-tab label="{{ 'Inventory.Offersarchived' | translate }}">
      <div class="buton-control">
        <button mat-button (click)="exportExcelArchived()" mat-raised-button color="primary">{{'Inventory.Export' | translate }}</button>
      </div>
    <div class="offers" fxLayoutAlign="center">
      <div class="available-offers" fxFlex.gt-sm="75%" fxFlex="100%" fxLayoutAlign="space-around start">
        <div *ngIf="counter != 0">
          <div *ngFor="let offer of archivedOffers">
            <mat-card class="offer">
              <div fxLayout.gt-xs="row" fxLayout="column" fxLayoutAlign="center center">
                <div class="offer-li" fxLayoutAlign="space-around center" fxFlex.gt-xs="5%" fxFlex="100%">
                  <mat-checkbox class="example-margin" [(ngModel)]="offer.export_check"></mat-checkbox>
                </div>
                <!-- <input matInput type="checkbox" [value]="offer._id"> -->
                <div class="offer-li" fxLayoutAlign="space-around center" fxFlex.gt-xs="20%" fxFlex="100%">
                  <img fxFlex.xs="50%" matCardImage
                    [src]="offer.img != null ? ('../../../assets/img/products/' + offer.img) : ('../../../assets/img/products/unknown.jpg')">
                </div>
                <div fxLayout="row wrap" fxLayoutAlign.gt-xs="start center" fxLayoutAlign="space-around center"
                  fxFlex.gt-xs="75%" fxFlex="100%">
                  <div class="offer-li" fxFlex.xs="50%">
                    <span>{{ 'Inventory.Product' | translate }}: {{offer.productName | translate }}</span>
                  </div>
                  <div class="offer-li" fxFlex.xs="50%">
                    <span>{{ 'Inventory.Farmer' | translate }}: {{offer.user.name}}</span>
                  </div>
                  <div class="offer-li" fxFlex.xs="50%">
                    <span>{{ 'Inventory.Quantity' | translate }}: {{offer.quantity}} {{offer.quantityType | translate
                      }}</span>
                  </div>
                  <div class="offer-li" fxFlex.xs="50%">
                    <span>{{ 'Inventory.Price' | translate }}: {{offer.price}} {{ 'lei' | translate }}</span>
                  </div>
                  <div class="offer-li" fxFlex.xs="50%">
                    <span>{{ 'Inventory.dateadded' | translate }}: {{ stringAsDate(offer.createdAt) | date:'dd/MM/yy
                      HH:mm' }}</span>
                  </div>
                  <div class="offer-li" fxFlex.xs="50%">
                    <span>{{ 'Inventory.note' | translate }}: {{offer.note}}</span>
                  </div>
                </div>
                <div class="offer-li" fxLayout="column" fxLayoutAlign="space-around end" fxFlex.xs="100%">
                  <div fxLayout="row">
                  </div>
                  <div fxLayout="row" *ngIf="offer.requestedAmount == 0 || !offer.requestedAmount">
                    <mat-form-field class="small-input">
                      <mat-label>{{ 'Inventory.Amount' | translate }}</mat-label>
                      <input matInput type="number" min="0" [max]="offer.quantity" [value]="offer.quantity"
                        (input)="offer.amount = $event.target.value" #orderAmount>
                    </mat-form-field>
                    <button mat-button [disabled]="offer.requestedAmount > 0"
                      (click)="order(offer._id, offer.amount || offer.quantity); orderAmount.value=''" mat-raised-button
                      color="primary">{{offer.transport ? "Ordered" : "Order" | translate}} </button>
                  </div>
                </div>
              </div>
            </mat-card>
          </div>
          <mat-paginator-goto aria-label="Select page" [length]="offersArchived.totalSize" [pageIndex]="0"
            [pageSize]="offersArchived.pageSize" [pageSizeOptions]="[5, 10, 25, 100]" (page)="handlePageArchived($event)">
          </mat-paginator-goto>
        </div>
        <div *ngIf="counter == 0">
          <h2>{{ 'Inventory.nooffers' | translate }}</h2>
        </div>
      </div>
    </div>
  </mat-tab>
  <mat-tab label="{{ 'Inventory.Users' | translate }}">
    <div class="offers" fxLayoutAlign="center">
      <div class="available-offers" fxFlex.gt-sm="75%" fxFlex="100%" fxLayout="column" fxLayoutAlign="space-around center">
          <div *ngFor="let user of users; let i = index">
            <mat-card class="offer">
              <div >
                <form (ngSubmit)="updateUser(user._id, $event)" class="register-container">
                  <mat-form-field class="user-edit-input" appearance="fill">
                    <mat-label>{{ 'Register.Fullname' | translate }}</mat-label>
                    <input matInput disabled [value]="user.name">
                  </mat-form-field>
                  <mat-form-field class="user-edit-input" appearance="fill">
                    <mat-label>{{ 'Register.Lastname' | translate }}</mat-label>
                    <input name="lastname" matInput [value]="user.lastname">
                  </mat-form-field>
                  <mat-form-field class="user-edit-input" appearance="fill">
                    <mat-label>{{ 'Register.Firstname' | translate }}</mat-label>
                    <input name="firstname" matInput [value]="user.firstname">
                  </mat-form-field>
                  <mat-form-field class="user-edit-input" appearance="fill">
                    <mat-label>{{ 'Register.Username' | translate }}</mat-label>
                    <input name="username" matInput [value]="user.username">
                  </mat-form-field>
                  <br>
                  <mat-form-field class="user-edit-input" appearance="fill">
                    <mat-label>{{ 'Register.Phone' | translate }}</mat-label>
                    <input name="phone" type="tel" matInput [value]="user.phone">
                  </mat-form-field>
                  <mat-form-field class="user-edit-input" appearance="fill">
                    <mat-label>{{ 'Register.Role' | translate }}</mat-label>
                    <mat-select [value]="user.roles[0].name" disabled>
                      <mat-option value="farmer">{{ 'Register.Farmer' | translate }}</mat-option>
                      <mat-option value="coordinator">{{ 'Register.coordinator' | translate }}</mat-option>
                      <mat-option value="partner">{{ 'Register.Partner' | translate }}</mat-option>
                      <mat-option value="manager">{{ 'Register.Manager' | translate }}</mat-option>
                      <mat-option value="delivery">{{ 'Register.Deliveryman' | translate }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <button type="submit" mat-raised-button color="primary">{{ 'Register.Update' | translate }}</button>
                </form>
              </div>
            </mat-card>
          </div>
          <mat-paginator-goto aria-label="Select page" [length]="usersPagination.totalSize" [pageIndex]="0"
            [pageSize]="usersPagination.pageSize" [pageSizeOptions]="[5, 10, 25, 100]" (page)="handlePageUsers($event)">
          </mat-paginator-goto>
        </div>
        <div *ngIf="counter == 0">
          <h2>{{ 'Inventory.nooffers' | translate }}</h2>
        </div>
    </div>
  </mat-tab>
  <!-- <mat-tab label="{{ 'Inventory.InventoryEditor' | translate }}">
    <div class="editor-page" fxLayoutAlign="space-around start">
      <div fxFlex.gt-sm="75%" fxFlex="100%" fxLayoutAlign="end start">
        <button mat-button (click)="openDialogNewItem()" mat-raised-button color="primary">{{'Inventory.Export' | translate }}</button>
      </div>
    </div>
    <div class="editor-page" fxLayoutAlign="space-around start">
      <div fxFlex.gt-sm="75%" fxFlex="100%" fxLayoutAlign="center start">
        <mat-accordion fxFlex="100%">
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Legume
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p>I'm visible because I am open</p>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Fructe
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p>I'm visible because I am open</p>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Verdeturi
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p>I'm visible because I am open</p>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </mat-tab> -->
</mat-tab-group>