import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

// const CLIENT_API = 'http://localhost:3000/api/clients/';
// const DELIVERY_API = 'http://localhost:3000/api/delivery/';
const CLIENT_API = 'https://app.farms.md/api/clients/';
const DELIVERY_API = 'https://app.farms.md/api/delivery/';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class DeliveryService {
  constructor(private http: HttpClient) { }

  getDeliveries(filter = {}): Observable<any> {
    return this.http.get(DELIVERY_API, {
      params: filter
    });
  }

  createDelivery(delivery): Observable<any> {
    return this.http.post(DELIVERY_API, {
      createdBy: delivery.createdBy,
      clientId: delivery.clientId,
      weight: delivery.bagWeight,
      size: delivery.bagSize
    }, httpOptions);
  }

  setDelivered(deliveryId): Observable<any> {
    return this.http.put(DELIVERY_API, {
      deliveryId: deliveryId,
      deliveredTime: Date.now()
    }, httpOptions);
  }

  unsetDelivered(deliveryId): Observable<any> {
    return this.http.put(DELIVERY_API, {
      deliveryId: deliveryId,
      deliveredTime: Date.now()
    }, httpOptions);
  }

  deleteDelivery(deliveryId): Observable<any> {
    return this.http.delete(DELIVERY_API, {
      params: {deliveryId: deliveryId}
    });
  }

  createClient(client): Observable<any> {
    return this.http.post(CLIENT_API, {
      name: client.name,
      phone: client.phone,
      city: client.city,
      address: client.street,
      houseNumber: client.number
    }, httpOptions);
  }
  
  editClient(clientId, client): Observable<any> {
    return this.http.put(CLIENT_API, {
      clientId: clientId,
      name: client.name,
      phone: client.phone,
      city: client.city,
      address: client.street,
      houseNumber: client.number
    }, httpOptions);
  }
  
  getClients(): Observable<any> {
    return this.http.get(CLIENT_API);
  }

}